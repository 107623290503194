import React, { useState } from 'react'
import Helper from "../../services/Helper"
import i18n from 'i18next'
import { Api, Constants } from 'scg.common-library'
import { ButtonPrimary } from '../general/Button'

async function sendReport(reportReasonId, explanation, userId, historyId) {
    const response = await Api.questionReport.createQuestionReport(reportReasonId, historyId, userId, explanation)
    if (response?.status === Constants.HTTP_CREATED) {
        Helper.displayMessage(i18n.t("game.quiz_report.success_send"))
    } else {
        Helper.displayMessage(i18n.t("error.generic"), "error")
    }
}


const QuizReport = ({question, historyId, userId, category, hide, reportReason}) => {
   const [comment, setComment] = useState("")
    const handleChange = (e) => {
        setComment(e.target.value)
    }
    const [value, setValue] = useState(null)
    const choiseResponse = (reportReasonId) => {
        setValue(reportReasonId)
    }

    const sendResponse = () => {
        if (value === null) {
            Helper.displayMessage(i18n.t("game.quiz_report.select_response"), "error")
        } else {
            sendReport(value, comment, userId, historyId)
            hide()
        }
    }

    return <div className="container-report" style={{padding: "0 10px"}}>
        <div>
            <h4 style={{margin: "10px 0 0"}}>{question}</h4>
        </div>
        <div>
            <span>{category}</span>
        </div>
        <div style={{marginTop: 15}}>
            {
                reportReason.map(element => {
                    const active = (element.id === value) ? "item-response" : ""
                    return <div key={element.id}>
                        <button className={`btn button-report ${active}`} type="button" onClick={() => {
                            choiseResponse(element.id)
                        }}>
                            {i18n.t(element.label)}
                        </button>
                    </div>
                })
            }
            <div style={{padding: "10px 0 25px"}}>
                <textarea className="report-description" rows={4}
                          placeholder={i18n.t("game.quiz_report.placeholder_comment")} onChange={handleChange}
                          value={comment}/>
            </div>
        </div>
        <div>
            <ButtonPrimary className="button-report-send"
                           onClick={sendResponse}>{i18n.t("game.quiz_report.send_button")}</ButtonPrimary>
        </div>
    </div>
}

export default QuizReport