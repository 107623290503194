import React from 'react'
import {Rating} from 'react-simple-star-rating'
import "./rate.css"
export function Rate(
    {
        libelle = ['Très facile', 'Facile', 'Moyenne', 'Difficile', 'Extrèmement Difficile'],
        callBackGettingValue = null,
        defaultText ='',
        captionText=''

    }) {

    // Catch Rating value
    const handleRating = (rate) => {
        if (callBackGettingValue !== null) callBackGettingValue(rate)
        // other logic
    }
    // Optinal callback functions
    /*const onPointerEnter = () => console.log('Enter')
    const onPointerLeave = () => console.log('Leave')*/
    /*const onPointerMove = (value, index) => {
        if(callBackGettingValue !== null) callBackGettingValue(value)
    }*/
    return (
        <div className="rating-layout">
            <span className="rating-libelle">{captionText}</span>
        <Rating
            onClick={handleRating}
            titleSeparator="/"
            tooltipArray={libelle}
            showTooltip={true}
            tooltipDefaultText={defaultText}
            tooltipStyle={{
                display: 'flex',
                flexDirection: 'column',
                touchAction: 'none',
                margin: 0,
                width: 'fit-content'
            }}
        />
        </div>
    )
}