import React from "react"

class TimerClock extends React.Component {
    static defaultProps = {
        width: 100,
        height: 100,
        lineWidth: 5,
        timeElapsedHandler: () => {}
    }

    constructor(props) {
        super(props)
        this.state = {
            timeLeft: props.timeLeft,
            coords: this.initCoords(props.timeLeft),
            currentStep: 1,
            width: props.width,
            height: props.height,
            lineWidth: props.lineWidth,
            center: [props.width / 2, props.height / 2],
            radius: Math.min(props.width, props.height) / 2 - props.lineWidth
        }
        this.countDown = this.countDown.bind(this)
    }

    componentDidMount() {
        const timeLeft = this.state.timeLeft * 1000
        this.draw(this.getCanvasContext())
        this.intervalID = setInterval(
            () => this.tick(this.getCanvasContext()),
            100
        )
        this.intervalID2 = setInterval(this.countDown, 1000)
        this.timeOutID = setTimeout(
            () => {
                clearInterval(this.intervalID)
                clearInterval(this.intervalID2)
                this.props.timeElapsedHandler()
            },
            timeLeft
        )
    }

    componentWillUnmount() {
        clearInterval(this.intervalID)
        clearInterval(this.intervalID2)
        clearTimeout(this.timeOutID)
    }

    initCoords(timeLeft) {
        const steps = timeLeft * 10
        const coords = Array(steps)

        for (let i = 1; i <= steps; i++) {
            const angle = (Math.PI / 2) - (i * (2 * Math.PI / steps))
            const cos = Math.cos(angle)
            const sin = Math.sin(angle)
            coords[i] = [cos, sin]
        }

        return coords
    }

    countDown() {
        this.setState({
            timeLeft: this.state.timeLeft - 1
        })
    }

    getCanvasContext() {
        const canvas = document.querySelector("#timerClock")
        return canvas.getContext("2d")
    }

    draw(ctx) {
        //Outer circle
        ctx.beginPath()
        ctx.arc(...this.state.center, this.state.radius, 0, Math.PI * 2, true)
        ctx.strokeStyle = "#6ec1d5"
        ctx.lineWidth = this.state.lineWidth
        ctx.stroke()

        //Center
        ctx.beginPath()
        ctx.arc(...this.state.center, this.state.radius / 7, 0, Math.PI * 2, true)
        ctx.fillStyle = "#6298da"
        ctx.shadowColor = "#6298da"
        //ctx.shadowBlur = 5
        ctx.fill()

        //Initial position
        ctx.beginPath()
        ctx.strokeStyle = "#6298da"
        ctx.moveTo(...this.state.center)
        ctx.lineTo(this.state.height / 2, this.state.radius / 2)
        ctx.stroke()
    }

    tick(ctx) {
        ctx.clearRect(0, 0, this.state.width, this.state.height)

        this.draw(ctx)

        const x = this.state.coords[this.state.currentStep][0]
        const y = this.state.coords[this.state.currentStep][1]
        const lineLength = this.state.radius / 1.2
        ctx.beginPath()
        ctx.strokeStyle = "#6298da"
        ctx.moveTo(...this.state.center)
        ctx.lineTo(this.state.center[0] + (x * lineLength), this.state.center[1] - (y * lineLength))
        ctx.stroke()

        this.setState({
            currentStep: this.state.currentStep + 1
        })
    }

    render() {
        return (
            <>
                <canvas
                    id="timerClock"
                    width={this.props.width}
                    height={this.props.height}>
                </canvas>
                {/* <h2 className="timer">{this.state.timeLeft}</h2> */}
            </>
        )
    }
}

class TimerText extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            timeLeft: props.timeLeft
        }
    }

    componentDidMount() {
        const timeLeft = this.state.timeLeft * 1000
        this.intervalID = setInterval(
            () => this.countDown(),
            1000
        )
        this.timeOutID = setTimeout(
            () => clearInterval(this.intervalID),
            timeLeft
        )
    }

    componentWillUnmount() {
        clearInterval(this.intervalID)
        clearTimeout(this.timeOutID)
    }

    countDown() {
        this.setState({
            timeLeft: this.state.timeLeft - 1
        })
    }

    render() {
        return (
            <>
                {this.state.timeLeft}
            </>
        )
    }
}

export {TimerText, TimerClock}