import React, {useState} from "react"
import {Navigate} from "react-router-dom"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {
    routes as Routing,
    setRouteParameters
} from "../../services/RoutesHelper"
import {Api, Session, Constants} from "scg.common-library"
import {TimerClock, TimerText} from "./Timer"
import Level from "./Level"
import {Loading} from "../general/Loading"
import Helper from "../../services/Helper"
import i18n from "i18next"
import Modal from "../general/Modal"

import "./quiz.css"
import QuizReport from "./QuizReport"

class QuizHomePage extends React.Component {
    #selectedAnswers = new Set()

    constructor(props) {
        super(props)
        this.state = {
            user: null,
            quizId: null,
            numberOfQuestions: 3,
            questionCounter: 1,
            timeLeft: this.props?.parametersObject?.timeLeft ?? 60,
            question: {
                id: null,
                category: "",
                text: "",
                levelId: null,
                levelShortName: "bronze"
            },
            answers: [],
            currentHistoryId: null,
            previousHistoriesIds: [],
            //previousQuestions: [],
            //selectedAnswers: [],
            submittedAnswers: [],
            quizFinished: false,
            loading: true,
            modalReport: false,
            reportReason: [],
            stopQuiz: false,
            canAccessPage: true
        }
        this.startQuiz = this.startQuiz.bind(this)
        this.nextQuestion = this.nextQuestion.bind(this)
        this.toggleSelectAnswer = this.toggleSelectAnswer.bind(this)
        this.nextQuestion = this.nextQuestion.bind(this)
        this.answersData = []
        this.openModal = this.openModal.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.getAllReportReason = this.getAllReportReason.bind(this)
    }

    async getAllReportReason() {
        const response = await Api.reportReason.getReportsReason()
        if (response?.status === Constants.HTTP_OK) {
            this.setState({
                reportReason: response.data
            })
        } 
    }

    shuffleArray(array) {
        const newArray = array.slice();
        
        for (let i = newArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
        }
        
        return newArray;
    }
    
    async responseBasedOnQuestion(questionDetails) {
        const responseTypeQuestion = await Api.question.getQuestion(
            questionDetails.question.id
        )
        const typeQuestion = Helper.isValidResponse(responseTypeQuestion)
        if (typeQuestion.questionType.shortName === "trueFalse") {
            const responseTrueOrFalse = await Api.truefalseLabel.getTruefasleLabels()
            const trueOrFalse = Helper.isValidResponse(responseTrueOrFalse)
            const responsesWithText = trueOrFalse.map(response => {
                return {
                    ...response,
                    text: i18n.t(response.label)
                };
            });
            return responsesWithText
        } else {
            return this.shuffleArray(questionDetails.answers)
        }
    }

    async startQuiz() {
        const user = Session.getSessionUser()

        const responseQuiz = await Api.quiz.startNewQuiz(user.id)
        if (responseQuiz.status === Constants.HTTP_OK) {
            const quiz = Helper.isValidResponse(responseQuiz)

            if (quiz.canStart) {
                const firstQuestionData = {
                    isFirstQuestion: true,
                    previousHistoriesIds: [],
                    previousHistoryId: null
                }

                const responseFirstQuestion = await Api.quiz.getNextQuestion(
                    quiz.id,
                    firstQuestionData
                )
                const firstQuestion = Helper.isValidResponse(responseFirstQuestion)
                this.setState({
                    user: user,
                    numberOfQuestions: quiz.numberOfQuestions,
                    quizId: quiz.id,
                    question: firstQuestion.question,
                    answers: await this.responseBasedOnQuestion(firstQuestion),
                    currentHistoryId: firstQuestion.currentHistoryId,
                    previousHistoriesIds: firstQuestion.previousHistoriesIds,
                    loading: false,
                    outOfTime: false
                })

                Api.history.startTimer(firstQuestion.currentHistoryId).then()
            } else {
                this.setState({
                    stopQuiz: true,
                    loading: false,
                    numberOfQuestions: quiz.numberOfQuestions,
                })
            }
        }
    }

    async nextQuestion() {
        this.setState({loading: true})

        if (this.state.questionCounter === this.state.numberOfQuestions) {
            const response = await Api.history
                .endTimer(this.state.currentHistoryId)
                .then()

            const currentSubmittedAnswers = {
                historyId: this.state.currentHistoryId,
                submittedAnswers: Array.from(this.#selectedAnswers)
            }

            this.answersData.push({
                historyId: this.state.currentHistoryId,
                submittedAnswers: Array.from(this.#selectedAnswers)
            })

            this.#selectedAnswers.clear()

            this.setState({
                submittedAnswers: this.state.submittedAnswers.concat(
                    currentSubmittedAnswers
                )
            })

            //save history
            if (response.status === 201) {
                const lastHistoryresponse = await Api.history.saveQuestionAndDetails(
                    currentSubmittedAnswers.historyId,
                    currentSubmittedAnswers.submittedAnswers,
                    Session.getJwtToken()
                )
                if (lastHistoryresponse.status === Constants.HTTP_OK || lastHistoryresponse.status === Constants.HTTP_CREATED) {
                    const responseQuizResume = await Api.quiz.getQuizSubmittedResume(this.state.quizId, this.state.submittedAnswers, 1)
                    if (responseQuizResume.status === Constants.HTTP_OK) {
                        this.setState({
                            quizFinished: true,
                            loading: false
                        })
                    }
                }
            }
        } else {
            const nextQuestionData = {
                isFirstQuestion: false,
                previousHistoriesIds: this.state.previousHistoriesIds,
                previousHistoryId: this.state.currentHistoryId
            }

            //const response = await Api.requestApi(`/quiz/${this.state.quizId}/next-question`, 'POST', nextQuestionData)
            const response = await Api.quiz.getNextQuestion(
                this.state.quizId,
                nextQuestionData
            )
            const data = Helper.isValidResponse(response)

            const currentSubmittedAnswers = {
                historyId: this.state.currentHistoryId,
                submittedAnswers: Array.from(this.#selectedAnswers)
            }

            this.answersData.push({
                historyId: this.state.currentHistoryId,
                submittedAnswers: Array.from(this.#selectedAnswers)
            })

            this.#selectedAnswers.clear()
            this.setState({
                submittedAnswers: this.state.submittedAnswers.concat(
                    currentSubmittedAnswers
                ),
                question: data.question,
                answers: await this.responseBasedOnQuestion(data),
                currentHistoryId: data.currentHistoryId,
                previousHistoriesIds: data.previousHistoriesIds,
                questionCounter: this.state.questionCounter + 1,
                loading: false,
                outOfTime: false
            })

            //save history
            if (response.status === 200) {
                Api.history.saveQuestionAndDetails(
                    currentSubmittedAnswers.historyId,
                    currentSubmittedAnswers.submittedAnswers,
                    Session.getJwtToken()
                )
            }

            //Api.requestApi(`/history/${data.currentHistoryId}/start-timer`, 'POST')
            Api.history.startTimer(data.currentHistoryId).then()
        }
    }

    componentDidMount() {
        const retrieveUserDetails = async () => {
            const response = await Api.user.getUser(
                Session.getSessionUser().id,
                true
            )
            if (response.status === 200) {
                const lastQuiz =
                Date.parse(response.data.user.lastQuiz) +
                response.data.user.society.parameter.timeBetweenQuiz * 60000
                const now = Date.now()
                if (lastQuiz > now || !this.props?.parametersObject?.fromNormalStep) {
                    //redirection vers le menu du jeu car il ne peut pas faire un nouveau quiz
                    this.setState({canAccessPage: false})
                } 
            } else {
                //redirection vers le menu du jeu car c'est ambigu vis à vis de l'echec
                this.setState({canAccessPage: false})
            }
        }
        //on lance la sécurisation du quiz si le chemin d'accès est appellé à la vollée sans suivre les etapes
        // if (this.props?.parametersObject?.fromNormalStep !== true) {
            retrieveUserDetails().then()
        // } else {
            this.startQuiz().then()
            this.getAllReportReason()
        // }

        window.addEventListener("beforeunload", this.beforeCloseAction, false)
        window.addEventListener(
            "visibilitychange",
            this.sendStopQuizReportAfterChangeFocus,
            false
        )
        window.addEventListener("unload", this.sendStopQuizReportAfterClose, false)
    }

    componentWillUnmount() {
        window.removeEventListener("beforeunload", this.beforeCloseAction, false)
        window.removeEventListener(
            "visibilitychange",
            this.sendStopQuizReportAfterChangeFocus,
            false
        )
        window.removeEventListener(
            "unload",
            this.sendStopQuizReportAfterClose,
            false
        )
    }

    toggleSelectAnswer(e) {
        e.preventDefault()
        if (this.state.outOfTime) {
            Helper.displayMessage(i18n.t("game.quiz_home.out_of_time_restriction"), "error")
            return
        }
        if (e.target.classList.contains("selected")) {
            e.target.classList.remove("selected")
            this.#selectedAnswers.delete(e.target.getAttribute("id"))
        } else {
            e.target.classList.add("selected")
            this.#selectedAnswers.add(Number(e.target.getAttribute("id")))
        }
    }

    sendStopQuizReportAfterClose = () => {
        Api.quiz.getQuizSubmittedResume(this.state.quizId, this.answersData, 0)
    }

    sendStopQuizReportAfterChangeFocus = () => {
        if (document.hidden) {
            Api.quiz.getQuizSubmittedResume(this.state.quizId, this.answersData, 0)
        }
    }

    beforeCloseAction = (e) => {
        e.preventDefault()
        e.returnValue = ""

    }

    openModal() {
        this.setState({
            modalReport: true
        })
    }

    closeModal() {
        this.setState({
            modalReport: false
        })
    }

    render() {
                    console.log("🚀 ~ QuizHomePage ~ getAllReportReason ~ reportReason:", this.state.reportReason)

        if (this.state.quizFinished) {
            return (
                <Navigate
                    state={{answers: this.state.submittedAnswers}}
                    to={setRouteParameters(Routing.quiz_correction.path, {
                        id: this.state.quizId
                    })}
                    replace
                />
            )
        }
        if (this.state.loading) {
            return <Loading/>
        }

        if (this.state.stopQuiz) {
            return <StopQuiz
                stopQuiz={this.state.stopQuiz}
                description={this.state.numberOfQuestions === 0 ?
                    i18n.t("game.quiz_home.information_not_available") :
                    i18n.t("game.quiz_home.not_enough_quiz")}
            />
        }

        if (!this.state.canAccessPage) {
            return <Navigate to={Routing.game.path}/>
        }

        const currentQuestionLevelRank = this.state.question.levelRank
        const currentQuestionLevelName = i18n.t("game.quiz_level."+this.state.question.levelShortName)

        console.log("🚀 ~ QuizHomePage ~ render ~ this.state.currentHistoryId:", this.state.currentHistoryId)
        return (
            <>
                <Modal title={i18n.t("game.quiz_report.title_modal")}
                       isShowing={this.state.modalReport}
                       hide={this.closeModal}>
                    <QuizReport
                        reportReason={this.state.reportReason}
                        question={this.state.question.text}
                        historyId={this.state.currentHistoryId}
                        userId={this.state.user.id}
                        category={this.state.question.category}
                        hide={this.closeModal}/>
                </Modal>
                <div className="quiz-container">
                    <div className="grid-col-3 col v-align h-align">
                        <TimerClock
                            timeLeft={this.state.timeLeft}
                            width={100}
                            height={100}
                            lineWidth={5}
                            timeElapsedHandler={() => {
                                Helper.displayMessage(i18n.t("game.quiz_home.out_of_time"), "error")
                                this.setState({outOfTime: true})
                            }}
                        />
                        <div className="category-wrapper">
                            <h2 className="category">{this.state.question.category}</h2>
                        </div>
                        <Level level={{rank: currentQuestionLevelRank, name: currentQuestionLevelName}}/>
                    </div>
                    <div className="flex-row row center v-align bg-black">
                        <span className="question">{this.state.question.text}</span>
                        <FontAwesomeIcon
                            title={i18n.t("game.quiz_report.title_flag")}
                            icon="fa-regular fa-flag"
                            className="icon next"
                            onClick={this.openModal}
                        />
                    </div>
                    <div className="grid-col-2 col v-align answers-container">
                        {this.state.answers.map((answer, index) => (
                            <div
                                id={answer.id}
                                className="flex-row v-align h-align row answer"
                                onClick={this.toggleSelectAnswer}
                                key={index}
                            >
                                {answer.isImageOnly ? (
                                    <img
                                        className="answer-image"
                                        src={answer.imageLink}
                                        alt={answer.text}
                                    />
                                ) : (
                                    answer.text
                                )}
                            </div>
                        ))}
                    </div>
                    <div className="flex-row row center v-align alert bg-black">
                        <FontAwesomeIcon icon="fa-regular fa-clock" className="icon"/>
                        <p>
                            <TimerText timeLeft={this.state.timeLeft}/>{" "}
                            <span className="">Secondes</span>
                        </p>
                        <FontAwesomeIcon
                            icon="fa-regular fa-circle-play"
                            className="icon next"
                            onClick={this.nextQuestion}
                        />
                    </div>
                    <div className="question-counter text-center">
                        <div>
                            {this.state.questionCounter}/{this.state.numberOfQuestions}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

function StopQuiz({stopQuiz, description}) {
    const [redirection, setRedirection] = useState(false)
    const backHome = () => {
        setRedirection(true)
    }
    if(redirection) {
        return <Navigate to={setRouteParameters(Routing.game.path,{})} />
    } else {
        return <>
            <Modal title={i18n.t("game.quiz_home.not_available")}
                   isShowing={stopQuiz}
                   hide={backHome}>
                <div className="text-center">
                    <div>
                        <div style={{width: 390, display: "inline-grid", marginBottom: 50}}>
                            {description}
                        </div>
                    </div>
                    <div>
                        <input
                            type='button'
                            className='btn default'
                            value={i18n.t("game.quiz_home.back_home")}
                            onClick={backHome}
                        />
                    </div>
                </div>
            </Modal>
        </>
    }
}

export default QuizHomePage




