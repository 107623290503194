import React from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {ButtonPrimary} from "../general/Button"
import {Api, Session} from "scg.common-library"
import {LoadingOverlay} from "../general/Loading"
import {NavLink} from "react-router-dom"
import ProfileParameters from "./ProfileParameters"
import SecurityParameters from "./SecurityParameters"
import LanguageParameters from "./LanguageParameters"
import i18n from "i18next"
import {routes as Routing} from "../../services/RoutesHelper"

import "./parameters.css"
import Helper from "../../services/Helper"

export default class ParametersPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            user: {},
            profileDisabled: false,
            securityDisabled: false,
            languageDisabled: false,
            loading: true,
            error: {
                status: false,
                message: ""
            },
            notification: {
                status: false,
                message: ""
            }
        }
        this.languages = null
        this.languageOptions = null
        this.toggleLoading = this.toggleLoading.bind(this)
        this.enableProfile = this.enableProfile.bind(this)
        this.enableSecurity = this.enableSecurity.bind(this)
        this.enableLanguage = this.enableLanguage.bind(this)
        this.enableAll = this.enableAll.bind(this)
        this.getLanguages = this.getLanguages.bind(this)
        this.getData = this.getData.bind(this)
    }

    toggleLoading() {
        this.setState({loading: !this.state.loading})
    }

    enableProfile() {
        this.setState({
            profileDisabled: false,
            securityDisabled: true,
            languageDisabled: true
        })
    }

    enableSecurity() {
        this.setState({
            profileDisabled: true,
            securityDisabled: false,
            languageDisabled: true
        })
    }

    enableLanguage() {
        this.setState({
            profileDisabled: true,
            securityDisabled: true,
            languageDisabled: false
        })
    }

    enableAll() {
        this.setState({
            profileDisabled: false,
            securityDisabled: false,
            languageDisabled: false
        })
    }

    componentDidMount() {
        this.getData()
    }

    async getData() {
        const response = await Api.user.checkUserJWT(Session.getJwtToken())
        const user = Helper.isValidResponse(response)

        this.getLanguages().then(()=>{
            this.setState({
                user: user,
                langs: this.languages,
                loading: false
            })
        })
    }

    async getLanguages() {
        const langsResponse = await Api.language.getLanguages()
        this.languages = Helper.isValidResponse(langsResponse)
        this.languageOptions = this.languages.map((lang) => {
            return <option key={lang.id} value={lang.id}>{lang.name} : {lang.code}</option>
        })
    }

    render() {
        return <>
            {this.state.loading
                ? <LoadingOverlay/>
                : <>
                    <NavLink to={Routing.game.path}>
                        <ButtonPrimary type="button" name="loginButton">
                            <FontAwesomeIcon icon="fa-solid fa-arrow-left"/> {i18n.t("parameter.back")}
                        </ButtonPrimary>
                    </NavLink>
                    <div className="parameter-content">
                        <h1>{i18n.t("parameter.parameters")}</h1>

                        {this.state.notification.status &&
                            <p className="param-notification">{this.state.notification.message}</p>}

                        {this.state.error.status && <p className="param-error">{this.state.error.message}</p>}

                        <div className="parameter-section paremeter-profil">
                            <ProfileParameters
                                user={this.state.user}
                                enable={this.enableProfile}
                                edition={false}
                                toggleLoad={this.toggleLoading}
                                onCancel={this.enableAll}
                                disabled={this.state.profileDisabled}/>
                        </div>
                        <div className="parameter-section paremeter-security">
                            <SecurityParameters
                                userId={this.state.user.id}
                                enable={this.enableSecurity}
                                toggleLoad={this.toggleLoading}
                                onCancel={this.enableAll}
                                disabled={this.state.securityDisabled}/>
                        </div>
                        <div className={"parameter-section paremeter-display"}>
                            <LanguageParameters
                                user={this.state.user}
                                enable={this.enableLanguage}
                                languages={this.languages}
                                languageOptions={this.languageOptions}
                                toggleLoad={this.toggleLoading}
                                onCancel={this.enableAll}
                                disabled={this.state.languageDisabled}/>
                        </div>
                    </div>
                </>
            }
        </>
    }
}
