import React from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {createPortal} from "react-dom"

import "./modal.css"

const Modal = ({isShowing, hide, title, ...props}) => {
    return isShowing ?
        createPortal(<>
                <section className="modal-overlay" style={{ zIndex: 1000 }}>
                    <div className="modal-wrapper">
                        <div className="modal">
                            <div className="modal-header">
                                <h4>{title}</h4>
                                <button type="button" className="btn alert modal-close-button" title="Fermer" onClick={hide} style={{cursor: "pointer"}}>
                                    <FontAwesomeIcon icon="fas fa-circle-xmark"/>
                                </button>
                            </div>
                            <div className="modal-body">{props.children}</div>
                        </div>
                    </div>
                </section>
            </>,
            document.body
        ) : null
}

export default Modal