import React, {useEffect, useState} from "react"
import i18n from "i18next"
import {NavLink} from "react-router-dom"
import {routes as Routing} from "../../services/RoutesHelper"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {Api} from "scg.common-library";
import { Tooltip } from 'react-tooltip'

export class UserLevel extends React.Component {
    static defaultProps = {
        user: null
    }

    constructor(props) {
        super(props)

        this.state = {
            currentLevel: "loading...",
            currentXpPercent: 0,
            currentXpFlat: 0,
            minExperience: 0,
            maxExperience: 1,
            menuOpen: false
        }
        this.toggleMenu = this.toggleMenu.bind(this)
        this.closeMenu = this.closeMenu.bind(this)
    }

    componentDidMount() {
        if (this.props.user) {
            const userLvl = this.props.user.level
            const currentXpFlat = this.props.user.currentExperience - userLvl.minExperience
            const maxXp = userLvl.maxExperience - userLvl.minExperience
            this.setState({
                currentXpPercent: ((currentXpFlat / maxXp) * 100).toFixed(2),
                currentXpFlat: currentXpFlat,
                currentLevel: userLvl.name.toUpperCase(),
                maxExperience: userLvl.maxExperience
            })
        }
    }

    toggleMenu() {
        this.setState((prevState) => {
            return {menuOpen: !prevState.menuOpen}
        })
    }

    closeMenu() {
        this.setState({menuOpen: false})
    }

    render() {
        return <div className="user-level">
            <div className="user-avatar-small">
                <img src="/img/avatar-small.png" alt="User small avatar" title={i18n.t("game.openMenu")}
                     onClick={this.toggleMenu} onBlur={this.closeMenu}/>
                {this.state.menuOpen && <UserMenu isActiveLicence={this.props.isActiveLicence}/>}
            </div>
            <div className="user-xp-bar">
                <div className="user-currentLevel">
                    <span>{i18n.t("game.level")} : </span>
                    <span>{this.state.currentLevel}</span>
                </div>
                <div className="userxp-wrapper" title={`${this.state.currentXpFlat} / ${this.state.maxExperience}`}>
                    <div className={`userxp-progress ${this.state.currentXpPercent <=0? "userxp-hidden":""}`} style={{width: `${this.state.currentXpPercent}%`}}>
                        {this.state.currentXpPercent}%
                    </div>
                </div>
                <UserMinLevelNegativePoints userId={this.props.user.id}/>
            </div>
        </div>
    }
}

function UserMinLevelNegativePoints({userId}) {
    const [node,setNode] = useState(<FontAwesomeIcon icon="fa-solid fa-spinner" />)
    useEffect(() => {
        async function getDetails() {
            const result = await Api.user.getUser(userId, true)
            if (result.status === 200) {
                if (result.data.user.currentExperience > result.data.user.society.parameter.minLevelNegativePoints) {
                    setNode(<span data-tooltip-id="plus-minus" ><FontAwesomeIcon icon="fa-solid fa-plus" />  /  <FontAwesomeIcon icon="fa-solid fa-minus" /></span>)
                } else {
                    setNode("")
                }
            }
        }
        getDetails()
    }, [userId])
    return (
        <>
            {node}
            <Tooltip id="plus-minus" html={i18n.t("game.experience_info")}/>
        </>
    )
}

function UserMenu({...props}) {
    return <div className="user-menu" role="menu">
        <div className="user-menu-item" role="menuitem">
            <NavLink to={Routing.profile.path} title={i18n.t("game.profile")}>
                <FontAwesomeIcon icon="fa-solid fa-circle-user"/> {i18n.t("game.profile")}
            </NavLink>
        </div>
        <div className="user-menu-item" role="menuitem">
            <NavLink to={(props.isActiveLicence) ? Routing.information_center.path: "#"} title={i18n.t("game.information_center")}>
                <FontAwesomeIcon icon="fa-solid fa-circle-info"/> {i18n.t("game.information_center")}
            </NavLink>
        </div>
        <div className="user-menu-item" role="menuitem">
            <NavLink to={Routing.parameters.path} title={i18n.t("game.parameters")}>
                <FontAwesomeIcon icon="fas fa-gears"/> {i18n.t("route.parameters")}
            </NavLink>
        </div>
        <div className="user-menu-item" role="menuitem">
            <NavLink reloadDocument to={Routing.app_logout.path} title={i18n.t("session.logout")}>
                <FontAwesomeIcon icon="fas fa-power-off"/> {i18n.t("route.logout")}
            </NavLink>
        </div>
    </div>

}