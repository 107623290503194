import "./quizProgressionJauge.css"
import {useState} from "react";
import React from "react";
import i18n from "i18next"

function QuizProgressJauge({currentValue=9}){
    const [displayBull8,setDisplayBull8] = useState(false)
return (
    <svg viewBox="3.87 156.752 363.668 50.223" xmlns="http://www.w3.org/2000/svg" key={new Date()} onMouseOut={()=>hideBull()}>
        <path className={0<currentValue ? "jauge-released" : "jauge"}
              d="M 16.173 196.621 C 18.341 196.617 44.629 196.742 44.629 196.742 L 44.632 198.465 L 16.236 198.537 L 16.173 196.621 Z M 16.141 197.555 C 16.143 200.379 13.662 202.671 11.234 202.671 C 8.805 202.671 5.901 200.381 5.897 197.557 C 5.892 194.73 8.79 192.439 11.217 192.439 C 13.646 192.439 16.136 194.728 16.141 197.555 Z"
             transform="matrix(0.999999, 0.001727, -0.001727, 0.999999, 0.341215, -0.043337)">
        </path>
        <path className={1<currentValue ? "jauge-released" : "jauge"}
              d="M 55.001 196.717 C 57.169 196.713 83.457 196.838 83.457 196.838 L 83.46 198.561 L 55.064 198.633 L 55.001 196.717 Z M 54.969 197.651 C 54.971 200.475 52.49 202.767 50.062 202.767 C 47.633 202.767 44.729 200.477 44.725 197.653 C 44.72 194.826 47.618 192.535 50.045 192.535 C 52.474 192.535 54.964 194.824 54.969 197.651 Z"
              transform="matrix(0.999999, 0.001727, -0.001727, 0.999999, 0.341439, -0.110393)">
        </path>
        <path className={2<currentValue ? "jauge-released" : "jauge"}
              d="M 93.753 196.992 C 95.921 196.988 122.209 197.113 122.209 197.113 L 122.212 198.836 L 93.816 198.908 L 93.753 196.992 Z M 93.721 197.926 C 93.723 200.75 91.242 203.042 88.814 203.042 C 86.385 203.042 83.481 200.752 83.477 197.928 C 83.472 195.101 86.37 192.81 88.797 192.81 C 91.226 192.81 93.716 195.099 93.721 197.926 Z"
              transform="matrix(0.999999, 0.001727, -0.001727, 0.999999, 0.341972, -0.177317)">
        </path>
        <path className={3<currentValue ? "jauge-released" : "jauge"}
              d="M 132.36 197.111 C 134.528 197.107 160.816 197.232 160.816 197.232 L 160.819 198.955 L 132.423 199.027 L 132.36 197.111 Z M 132.328 198.045 C 132.33 200.869 129.849 203.161 127.421 203.161 C 124.992 203.161 122.088 200.871 122.084 198.047 C 122.079 195.22 124.977 192.929 127.404 192.929 C 129.833 192.929 132.323 195.218 132.328 198.045 Z"
              transform="matrix(0.999999, 0.001727, -0.001727, 0.999999, 0.342235, -0.243991)">
        </path>
        <path className={4<currentValue ? "jauge-released" : "jauge"}
              d="M 171.053 197.168 C 173.221 197.164 199.509 197.289 199.509 197.289 L 199.512 199.012 L 171.116 199.084 L 171.053 197.168 Z M 171.021 198.102 C 171.023 200.926 168.542 203.218 166.114 203.218 C 163.685 203.218 160.781 200.928 160.777 198.104 C 160.772 195.277 163.67 192.986 166.097 192.986 C 168.526 192.986 171.016 195.275 171.021 198.102 Z"
              transform="matrix(0.999999, 0.001727, -0.001727, 0.999999, 0.342391, -0.310814)">
        </path>

        <path className={5<currentValue ? "jauge-released" : "jauge"}
              d="M 209.839 197.168 C 212.007 197.164 238.295 197.289 238.295 197.289 L 238.298 199.012 L 209.902 199.084 L 209.839 197.168 Z M 209.807 198.102 C 209.809 200.926 207.328 203.218 204.9 203.218 C 202.471 203.218 199.567 200.928 199.563 198.104 C 199.558 195.277 202.456 192.986 204.883 192.986 C 207.312 192.986 209.802 195.275 209.807 198.102 Z"
              transform="matrix(0.999999, 0.001727, -0.001727, 0.999999, 0.342449, -0.377798)">
        </path>
        <path className={6<currentValue ? "jauge-released" : "jauge"}
              d="M 248.795 197.339 C 250.963 197.335 277.251 197.46 277.251 197.46 L 277.254 199.183 L 248.858 199.255 L 248.795 197.339 Z M 248.763 198.273 C 248.765 201.097 246.284 203.389 243.856 203.389 C 241.427 203.389 238.523 201.099 238.519 198.275 C 238.514 195.448 241.412 193.157 243.839 193.157 C 246.268 193.157 248.758 195.446 248.763 198.273 Z"
              transform="matrix(0.999999, 0.001727, -0.001727, 0.999999, 0.342802, -0.445074)">
        </path>
        <path className={7<currentValue ? "jauge-released" : "jauge"}
              d="M 287.581 197.509 C 289.749 197.505 316.037 197.63 316.037 197.63 L 316.04 199.353 L 287.644 199.425 L 287.581 197.509 Z M 287.549 198.443 C 287.551 201.267 285.07 203.559 282.642 203.559 C 280.213 203.559 277.309 201.269 277.305 198.445 C 277.3 195.618 280.198 193.327 282.625 193.327 C 285.054 193.327 287.544 195.616 287.549 198.443 Z"
              transform="matrix(0.999999, 0.001727, -0.001727, 0.999999, 0.343153, -0.512057)" >
        </path>
        <path className={8<currentValue ? "jauge-released" : "jauge"}
              d="M 326.537 197.338 C 328.705 197.334 354.993 197.459 354.993 197.459 L 354.996 199.182 L 326.6 199.254 L 326.537 197.338 Z M 326.505 198.272 C 326.507 201.096 324.026 203.388 321.598 203.388 C 319.169 203.388 316.265 201.098 316.261 198.274 C 316.256 195.447 319.154 193.156 321.581 193.156 C 324.01 193.156 326.5 195.445 326.505 198.272 Z"
              transform="matrix(0.999999, 0.001727, -0.001727, 0.999999, 0.342916, -0.579335)">
        </path>
        <path className={9<currentValue ? "jauge-released" : "jauge"}
              d="M 365.291 198.247 C 365.293 201.071 362.812 203.363 360.384 203.363 C 357.955 203.363 355.051 201.073 355.047 198.249 C 355.042 195.422 357.94 193.131 360.367 193.131 C 362.796 193.131 365.286 195.42 365.291 198.247 Z"
              transform="matrix(0.999999, 0.001727, -0.001727, 0.999999, 0.34291, -0.621716)">
        </path>
        <text className="step-number" x="10.926" y="200.204" 
              transform="matrix(0.943031, 0, 0, 1.060451, -1.71274, -11.255894)">1
        </text>
        <text className="step-number" x="71.561" y="199.791" 
              transform="matrix(0.943031, 0, 0, 1.060451, -19.71274, -11.255894)">2
        </text>
        <text className="step-number" x="131.384" y="199.945" 
              transform="matrix(0.943031, 0, 0, 1.060451, -37.205334, -11.009596)">3
        </text>
        <text className="step-number" x="191.376" y="199.639" 
              transform="matrix(0.943031, 0, 0, 1.060451, -55.451633, -11.255894)">4
        </text>
        <text className="step-number" x="251.675" y="199.486" 
              transform="matrix(0.943031, 0, 0, 1.060451, -73.466438, -10.516999)">5
        </text>
        <text className="step-number" x="10.926" y="200.204" 
              transform="matrix(0.943031, 0, 0, 1.060451, 192.708282, -10.818231)">6
        </text>
        <text className="step-number" x="71.561" y="199.791" 
              transform="matrix(0.943031, 0, 0, 1.060451, 174.519669, -10.818231)">7
        </text>
        <text className={currentValue<8 ? "step-number spoted-number" : "step-number"} x="131.384" y="199.945"
              onMouseOver={()=>displayBull()} onMouseOut={()=>hideBull()}
              transform="matrix(0.943031, 0, 0, 1.060451, 156.461197, -10.571933)">8
        </text>
        <text className="step-number" x="191.376" y="199.639" 
              transform="matrix(0.943031, 0, 0, 1.060451, 138.780762, -10.818231)">9
        </text>
        <text className="step-number" x="251.675" y="199.486" 
              transform="matrix(0.943031, 0, 0, 1.060451, 118.249817, -10.079336)">10
        </text>

        <path style={{display: displayBull8? 'block' : 'none'}} className="jauge" d="M 274.866 179.423 C 282.048 179.591 281.267 179.834 282.071 185.206 C 282.571 188.546 283.101 191.027 283.061 191.392 C 283.039 191.597 283.738 185.997 284.041 184.963 C 285.503 179.975 283.239 179.599 294.838 179.834 C 296.742 179.873 305.337 179.821 307.204 179.821 C 314.152 179.907 317.014 176.88 317.769 171.463 C 318.574 165.689 316.407 159.548 307.224 159.67 C 301.584 159.745 289.741 159.413 282.876 159.269 C 281.12 159.232 264.738 159.031 259.546 159.473 C 245.401 160.677 247.627 179.68 259.52 179.402 C 262.594 179.33 270.29 179.434 274.866 179.423 Z">
        </path>

        <text style={{display: displayBull8? 'block' : 'none'}} className="bull-text" transform="matrix(1.013083, 0, 0, 1, -4.127123, -2)"><tspan x="255.303" y="169.142">{i18n.t("game.quiz_progress.unlock_8")}</tspan><tspan x="255.303" dy="1em">​</tspan><tspan>{i18n.t("game.quiz_progress.surprize")}</tspan></text>

    </svg>
)
    function displayBull(){
        currentValue<8 ? setDisplayBull8(true) : setDisplayBull8 (false)
    }
    function hideBull(){
        setDisplayBull8 (false)
    }
}
export default QuizProgressJauge