import React from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"

import "./input.css"

class InputField extends React.Component {

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    getFocus(e) {
        e.target.classList.add("formField--focused")
    }

    looseFocus(e) {
        const element = e.target
        if (element.value === "") {
            element.classList.remove("formField--focused")
        }
    }

    render() {
        const {
            type,
            children,
            name,
            value,
            title,
            context,
            onChange,
            forwardedRef,
            required,
            placeHolder,
            isFocused,
            className,
            inputStyle,
            disabled,
            noPadding,
            checked,
            min=null,
            max=null,
            step=null
        } = this.props
        const currentContext = context ?? this
        const inputType = type ?? "text"
        const style = inputStyle ?? "futurist"
        const placeHolderText = placeHolder ?? ""
        const classNames = className ?? ""
        // eslint-disable-next-line no-unused-vars
        const isRequired = required ? "required" : "" //todo: use this required props
        const isDisabled = disabled ? "disabled" : "" //todo: use this required props
        const focused = isFocused ? "formField--focused" : ""
        const paddingClass = noPadding ? "formField--nopadding" : ""
        const onInputChange = onChange ?? this.handleChange.bind(currentContext)
        var dynamicAttribute = {}
        if (inputType === "number") {
            if (min !== null) dynamicAttribute["min"] = min
            if (max !== null) dynamicAttribute["max"] = max
            if (step !== null) dynamicAttribute["step"] = step
        }
        if (inputType === "checkbox") {
            dynamicAttribute["checked"] = checked
        }
        return <div className={`formField formField-input formField-style-${style} ${classNames}`}>
            <input type={inputType} id={name} name={name} title={title} className={`${focused} ${isDisabled} ${paddingClass}`}
                   ref={forwardedRef} value={value} placeholder={placeHolderText}
                   onChange={onInputChange} onFocus={this.getFocus.bind(currentContext)}
                   onBlur={this.looseFocus.bind(currentContext)} {...dynamicAttribute}></input>
            <label htmlFor={name}>{children} {isRequired && <Requiredstar/>}</label>
            <span className={"formField-underline"}></span>
        </div>
    }
}

class TextareaField extends React.Component {

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    getFocus(e) {
        e.target.classList.add("formField--focused")
    }

    looseFocus(e) {
        const element = e.target
        if (element.value === "") {
            element.classList.remove("formField--focused")
        }
    }


    render() {
        const {children, name, value, title, rows, cols, context,placeHolder,onChange} = this.props
        const currentContext = context ?? this
        const onItChange = onChange ?? this.handleChange.bind(currentContext)
        return <div className={"formField formField-textarea"}>
            <textarea id={name} name={name} title={title} rows={rows} cols={cols}
                      value={value} onChange={onItChange} onFocus={this.getFocus.bind(context)}
                      onBlur={this.looseFocus.bind(context)} placeholder={placeHolder}></textarea>
            <label htmlFor={name}>{children}</label>
            <span className={"formField-underline"}></span>
        </div>
    }
}

const Requiredstar = () => {
    return <div className="required-star"><FontAwesomeIcon icon="fas fa-star-of-life"></FontAwesomeIcon></div>
}

export {
    InputField, TextareaField, Requiredstar
}