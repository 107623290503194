import React from "react"
import SoundService from "../../services/SoundService"
import Helper from "../../services/Helper"
import moment from "moment"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"

import "./button.css"
import i18n from "i18next"

class SimpleButton extends React.Component {

    render() {
        let {type, className, name, title, onClick, onMouseEnter, children, disabled} = this.props

        type = type ?? "button"
        className = className ?? ""

        return <button type={type} className={`simple-button ${className}`}
                       name={name} title={title} onClick={onClick} onMouseEnter={onMouseEnter} disabled={disabled}>
            {children}
        </button>
    }
}

class ButtonPrimary extends React.Component {

    handleClick() {
        // console.log("button clicked")
        SoundService.playSound("tone.wav", "buttonClicked", "sounds")
    }

    handleHover() {
        // console.log("button clicked")
        SoundService.playSound("play.wav", "buttonHovered", "sounds")
    }

    getUniqueId() {
        return Helper.generateUUID(5)
    }

    render() {
        let {type, className, name, title, context, onClick, onMouseEnter, children, disabled, nosound} = this.props

        type = type ?? "button"
        className = className ?? ""
        if (!nosound) {
            onClick = onClick ?? this.handleClick.bind(context)
            onMouseEnter = onMouseEnter ?? this.handleHover.bind(context)
        }

        //id={`button-${this.getUniqueId()}`}
        return <button type={type} className={`neoButton-primary ${className}`}
                       name={name} title={title} onClick={onClick} onMouseEnter={onMouseEnter} disabled={disabled}>
            {children}
        </button>
    }
}

class TimingButton extends React.Component {
    static defaultProps = {
        step: 1,
        delay: 60000,
        eventType: "évènement",
        classPrefix: "event",
        iconClassName: "fa-clipboard-question",
        buttonName: "button",
        nextEventTime: 0
    }

    constructor(props) {
        super(props)
        this.state = {
            nextEventTime: 0,
            eventStatus: false
        }
        this.decrement = this.decrement.bind(this)
    }

    componentDidMount() {
        this.setState((prevState, props) => ({
            nextEventTime: props.nextEventTime,
            eventStatus: props.nextEventTime === 0
        }))
        this.play()
    }

    decrement() {
        this.setState((prevState, props) => {
            let nextEventTime = prevState.nextEventTime - props.step
            nextEventTime = (nextEventTime < 0) ? 0 : nextEventTime
            return {
                nextEventTime: nextEventTime,
                eventStatus: nextEventTime === 0
            }
        })
    }

    play() {
        setInterval(this.decrement, this.props.delay)
    }

    render() {
        let timeLeft = moment.utc(moment.duration(this.state.nextEventTime, "minutes").asMilliseconds()).format("HH:mm")
        let indicationTime = i18n.t("game.event.hours")
        if (this.state.nextEventTime < 60) {
            indicationTime = i18n.t("game.event.minutes")
            timeLeft = moment.utc(moment.duration(this.state.nextEventTime, "minutes").asMilliseconds()).format("mm")
        }
        const icon = (this.state.eventStatus) ? this.props.iconClassName : "fa-lock"
        const locked = (!this.state.eventStatus && this.state.nextEventTime !== 0)
        const eventType = this.props.eventType.charAt(0).toUpperCase() + this.props.eventType.slice(1)
        let timeText = i18n.t("game.event.eventTime", {event: eventType, time: timeLeft})
        if (this.state.nextEventTime < 1) {
            indicationTime = ""
            timeText = i18n.t("game.event.lessOneMinutes", {event: eventType})
        }
        return <>
            <ButtonPrimary type="button" name={this.props.buttonName} disabled={locked}>
                <FontAwesomeIcon icon={`fas ${icon}`}/>
                <span> {i18n.t("game.event.eventOfTheDay", {event: eventType})}</span>
            </ButtonPrimary>
            <span className={`${this.props.classPrefix}-timer-message ${locked ? "" : "available"}`}>
                {locked
                    ? <>
                        <FontAwesomeIcon icon="fas fa-lock"/>
                        <span> {timeText+indicationTime}</span>
                    </>
                    : <> {i18n.t("game.event.eventAvailable", {event: eventType})}</>
                }
            </span>
        </>
    }
}

export {
    SimpleButton, ButtonPrimary, TimingButton
}