import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {NavLink, useNavigate} from "react-router-dom"
import {ButtonPrimary} from "../general/Button"
import {routes as Routing} from "../../services/RoutesHelper"
import i18n from "i18next"

import "./quiz.css"
import {useLocation} from "react-router"
import { useEffect } from "react"

function QuizInformation() {
    const locationObjects = useLocation()


    const navigate = useNavigate();

    useEffect(() => {
        const cantAccessPage = () => {
            if (!locationObjects?.state?.fromNormalStep || locationObjects?.state?.quizTimeLeft > 0) {
                navigate(Routing.game.path);
            }
        }
        cantAccessPage();
    }, [locationObjects?.state, navigate])
    
    return <div className="items_group">
        <h1 style={{textAlign: "center"}}>{i18n.t("game.quiz_info.title")}</h1>
        <div className="items_title brighting_text">
            <FontAwesomeIcon icon="fa-solid fa-shield-halved" size="2x"/>
            <span style={{paddingLeft: 27}}>{i18n.t("game.quiz_info.questions", {"number": locationObjects?.state?.questionNumber})}</span>
        </div>

        <div className="items_title brighting_text">
            <FontAwesomeIcon icon="fa-solid fa-hourglass-end" size="2x"/>
            <span style={{paddingLeft: 27}}> {i18n.t("game.quiz_info.delay", {"delay": locationObjects?.state?.delay})}</span>
        </div>

        <div className="items_title brighting_text">
            <FontAwesomeIcon icon="fa-solid fa-thumbs-down" size="2x"/>
            <span style={{paddingLeft: 27}}>{i18n.t("game.quiz_info.rule")}</span>
        </div>

        <div className="items_title brighting_text">
            <FontAwesomeIcon icon="fa-solid fa-circle-exclamation" size="2x"/>
            <span style={{paddingLeft: 27}}>{i18n.t("game.quiz_info.abort")}</span>
        </div>

        <div className="items_title brighting_text">
            <FontAwesomeIcon icon="fa-solid fa-flag" size="2x"/>
            <span style={{paddingLeft: 27}}>{i18n.t("game.quiz_info.report")}</span>
        </div>

        <div style={{alignItems: "center", display: "flex", flexDirection: "column"}}>
            <div style={{display: "flex"}}>
                <NavLink to={Routing.quiz_progress.path} title={i18n.t("game.quiz_info.back")}>
                    <ButtonPrimary type="button" name="back">
                        <FontAwesomeIcon icon="fa-solid fa-left-long"/>
                        <span>{i18n.t("game.quiz_info.back")}</span>
                    </ButtonPrimary>
                </NavLink>
                <NavLink to={Routing.quiz.path} title={i18n.t("game.quiz_info.start")} state={{fromNormalStep:true,timeLeft: locationObjects?.state?.delay}}>
                    <ButtonPrimary type="button" name="start">
                        <FontAwesomeIcon icon="fa-solid fa-play"/>
                        <span>{i18n.t("game.quiz_info.start")}</span>
                    </ButtonPrimary>
                </NavLink>
            </div>
        </div>
    </div>
}

export default QuizInformation