import {ButtonPrimary} from "../general/Button"
import {NavLink} from "react-router-dom"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {routes as Routing} from "../../services/RoutesHelper"

export default function GameMenu({...props}) {
    return <>
        <NavLink to={Routing.reward.path} title="Accéder aux récompenses">
            <ButtonPrimary type="button" name="rewardButton">
                <FontAwesomeIcon icon="fas fa-gift"/> <span>Récompenses</span>
            </ButtonPrimary>
        </NavLink>

        <NavLink to={Routing.badge.path} title="Accéder aux badges">
            <ButtonPrimary type="button" name="badgesButton">
                <FontAwesomeIcon icon="fas fa-medal"/> <span>Badges</span>
            </ButtonPrimary>
        </NavLink>

        <NavLink to={(props.isActiveLicence) ? Routing.scoreboard.path : "#"} title="Accéder aux classement">
            <ButtonPrimary type="button" name="scoreboardButton">
                <FontAwesomeIcon icon="fas fa-ranking-star"/> <span>Scoreboard</span>
            </ButtonPrimary>
        </NavLink>
    </>
}
