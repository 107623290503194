import Login from "./Login"
import {Navigate} from "react-router-dom"
import {Session} from "scg.common-library"
import {routes as Routing} from "../../services/RoutesHelper"

import "./login.css"

export function LoginPage() {
    return <Login/>
}

export function LogoutPage() {
    Session.handleLogout()
    return <Navigate to={Routing.app_home.path} replace/>
}
