import {useState} from "react"
import {LoadingOverlay} from "./components/general/Loading"
import FrontRoutes from "./services/FrontRoutes"
import {VolumeMenu} from "./services/Volume"
import Header from "./components/general/Header"
import {Session} from "scg.common-library"
import {isGameRoute} from "./services/RoutesHelper"
import i18n from "i18next"
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector"
import {initReactI18next} from "react-i18next"
import Helper from "./services/Helper"
import {ToastContainer} from "react-toastify"
import 'react-toastify/dist/ReactToastify.css'
import 'react-tooltip/dist/react-tooltip.css'

/* ----- FontAwesome icons import ----- */
import {library} from "@fortawesome/fontawesome-svg-core"
import {fas} from "@fortawesome/free-solid-svg-icons"
import {far} from "@fortawesome/free-regular-svg-icons"
import {fab} from "@fortawesome/free-brands-svg-icons"

library.add(fas, far, fab)

export default function App() {
    const [translationLoading, setTranslationLoading] = useState(true)
    initializeTranslation().then(() => {
        setTranslationLoading(false)
    })

    return <>
        {translationLoading
            ? <LoadingOverlay/>
            : (isGameRoute(window.location.pathname) ? <GameApp/> : <MainApp/>)
        }
    </>
}

function MainApp() {
    const user = Session.getSessionUser()
    return <section className="app-section">
        <header className="header">
            <Header isLogged={Session.isLoggedIn()} user={user}/>
        </header>
        <article className="content" onScroll={changeHeaderBackground}>
            <FrontRoutes/>
            <footer className="footer">
                <div className="footer-version">Version {process.env.REACT_APP_VERSION}</div>
            </footer>
        </article>
    </section>
}

function GameApp() {
    return <section className="game-section">
        <article className="content">
            <FrontRoutes/>
            <ToastContainer/>
        </article>
        <footer className="footer">
            <div className="footer-version">Version {process.env.REACT_APP_VERSION}</div>
            <VolumeMenu className="footer-volume-menu" position="right"/>
        </footer>
    </section>
}

function changeHeaderBackground(event) {
    document.querySelector("header.header").style.backgroundColor = (event.target.scrollTop < 200) ? "transparent" : "var(--header-background)"
}


async function initializeTranslation() {
    // TODO: récupérer la langue de l'utilisateur, FR sinon.
    //  + Créer un composant langSwitcher qui s'occupera de charger dynamiquement les fichiers de langue
    //  + Stocker la traduction pour éviter de la recharger à chaque rendu ?

    const translations = await Helper.getLocalTranslations("FR")

    await i18n.use(I18nextBrowserLanguageDetector)
        .use(initReactI18next)
        .init({
            resources: {
                fr: {
                    translation: translations.FR
                },
                en: {
                    translation: translations.EN
                }
            },
            fallbackLng: "fr"
        })
}