import {Routes, Route, Navigate, useLocation} from "react-router-dom"
import HomePage from "../components/home/HomePage"
import GameHomePage from "../components/game/GameHomePage"
import {LoginPage, LogoutPage} from "../components/login/LoginPage"
import ParametersPage from "../components/parameters/ParametersPage"
import QuizHomePage from "../components/quiz/QuizHomePage"
import QuizProgression from "../components/quiz/QuizProgression"
import QuizInformation from "../components/quiz/QuizInformation"
import CorrectionPage from "../components/quiz/CorrectionPage"
import ChallengeHomePage from "../components/challenges/ChallengeHomePage"
import RewardsPage from "../components/rewards/RewardsPage"
import BadgePage from "../components/badges/BadgePage"
import ScoreboardPage from "../components/scoreboard/ScoreboardPage"
import ActivationPage from "../components/login/ActivationPage"
import ErrorPage from "../components/general/Error/ErrorPage"
import UserProfile from "../components/game/UserProfile"
import InformationCenter from "../components/game/InformationCenter"
import {routes as Routing} from "./RoutesHelper"
import {Session, Roles, Constants} from "scg.common-library"
import i18n from "i18next"
import CorrectionDetailsPage from "../components/quiz/CorrectionDetailsPage";
import HistoryPage from "../components/profile/history/HistoryPage";


function getQuizIdFromLocation(location) {
    const locationSplit = location.pathname.split("/")
    return locationSplit[locationSplit.length - 2]
}

export default function FrontRoutes() {
    const location = useLocation()

    return <Routes>
        <Route path={Routing.app_home.path} element={<SecureRoute minRole={null} route={Routing.app_home} component={HomePage}/>}/>
        <Route path={Routing.app_login.path} element={<SecureRoute minRole={null} route={Routing.app_login} component={LoginPage}/>}/>
        <Route path={Routing.app_logout.path} element={<SecureRoute minRole={null} route={Routing.app_logout} component={LogoutPage}/>}/>
        <Route path={Routing.app_activate.path} element={<SecureRoute minRole={null} route={Routing.app_activate} component={ActivationPage}/>}/>
        <Route path={Routing.game.path} element={<SecureRoute minRole={Constants.ROLE_PLAYER} route={Routing.game} component={GameHomePage}/>}/>
        <Route path={Routing.profile.path} element={<SecureRoute minRole={Constants.ROLE_PLAYER} route={Routing.profile} component={UserProfile}/>}/>
        <Route path={Routing.information_center.path} element={<SecureRoute minRole={Constants.ROLE_PLAYER} route={Routing.information_center} component={InformationCenter}/>}/>
        <Route path={Routing.parameters.path} element={<SecureRoute minRole={Constants.ROLE_PLAYER} route={Routing.parameters} component={ParametersPage}/>}/>
        <Route path={Routing.quiz.path} element={<SecureRoute minRole={Constants.ROLE_PLAYER} route={Routing.quiz} component={QuizHomePage} parametersObject={location.state}/>}/>
        <Route path={Routing.quiz_correction.path} element={<SecureRoute minRole={Constants.ROLE_PLAYER} route={Routing.quiz_correction} component={CorrectionPage} quizId={getQuizIdFromLocation(location)} data={location.state}/>}/>
        <Route path={Routing.quiz_correction_details.path} element={<SecureRoute minRole={Constants.ROLE_PLAYER} route={Routing.quiz_correction_details} component={CorrectionDetailsPage} quizId={getQuizIdFromLocation(location)} data={location.state} />}/>
        <Route path={Routing.quiz_progress.path} element={<SecureRoute minRole={Constants.ROLE_PLAYER} route={Routing.quiz_progress} component={QuizProgression}/>}/>
        <Route path={Routing.quiz_info.path} element={<SecureRoute minRole={Constants.ROLE_PLAYER} route={Routing.quiz_info} component={QuizInformation}/>}/>
        <Route path={Routing.challenge.path} element={<SecureRoute minRole={Constants.ROLE_PLAYER} route={Routing.challenge} component={ChallengeHomePage}/>}/>
        <Route path={Routing.reward.path} element={<SecureRoute minRole={Constants.ROLE_PLAYER} route={Routing.reward} component={RewardsPage}/>}/>
        <Route path={Routing.badge.path} element={<SecureRoute minRole={Constants.ROLE_PLAYER} route={Routing.badge} component={BadgePage}/>}/>
        <Route path={Routing.scoreboard.path} element={<SecureRoute minRole={Constants.ROLE_PLAYER} route={Routing.scoreboard} component={ScoreboardPage}/>}/>
        <Route path={Routing.history.path} element={<SecureRoute minRole={Constants.ROLE_PLAYER} route={Routing.history} component={HistoryPage}/>}/>

        <Route path="*" element={<ErrorPage code="404" title={i18n.t("error.notFound.title")} message={i18n.t("error.notFound.message")}/>}/>
    </Routes>
}

function SecureRoute({component: Component, minRole, route, ...props}) {
    if (minRole !== null) {
        const user = Session.getUser()

        if (!Session.isLoggedIn() || user === null) {
            return <Navigate to={Routing.app_login.path} replace/>
        }

        if (minRole != null && !Roles.isGranted(user?.roles[0], minRole)) {
            return <ErrorPage code="403" title={i18n.t("error.accesDenied.title")}
                message={i18n.t("error.accesDenied.message")}/>
        }
    }

    document.title = i18n.t(route.title) + " - " + Constants.DOCUMENT_TITLE_FRONTOFFICE
    return <Component {...props}/>
}
