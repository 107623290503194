import React from "react"
import i18n from "i18next"
import {Api, Session} from "scg.common-library"
import {ButtonPrimary} from "../general/Button"
import {InputField} from "../general/Input"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"

import "./SecurityParameters.css"

export default class SecurityParameters extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            userId: this.props.userId,
            loading: false,
            currentPassword: "",
            password: "",
            passwordConfirm: "",
            passwordLength: false,
            passwordNumber: false,
            passwordLowerCase: false,
            passwordUppercase: false,
            passwordSpecialChar: false,
            passwordsMatchs: false,
            passwordValidity: false,
            edition: false
        }
        this.handleCancel = this.handleCancel.bind(this)
        this.handlePasswordChange = this.handlePasswordChange.bind(this)
        this.handlePasswordConfirmChange = this.handlePasswordConfirmChange.bind(this)
        this.handleFormSubmit = this.handleFormSubmit.bind(this)
        this.checkPasswordLength = this.checkPasswordLength.bind(this)
        this.checkPasswordNumber = this.checkPasswordNumber.bind(this)
        this.checkPasswordLowerCase = this.checkPasswordLowerCase.bind(this)
        this.checkPasswordUppercase = this.checkPasswordUppercase.bind(this)
        this.checkPasswordSpecialChar = this.checkPasswordSpecialChar.bind(this)
        this.checkPasswordsMatchs = this.checkPasswordsMatchs.bind(this)
        this.checkPasswordValidity = this.checkPasswordValidity.bind(this)
    }

    handleCancel() {
        this.setState({edition: false})
        this.props.onCancel()
    }

    handlePasswordChange(e) {
        const newPassword = e.target.value

        this.setState({
            password: newPassword
        }, () => {
            this.setState({
                passwordLength: this.checkPasswordLength(),
                passwordNumber: this.checkPasswordNumber(),
                passwordLowerCase: this.checkPasswordLowerCase(),
                passwordUppercase: this.checkPasswordUppercase(),
                passwordSpecialChar: this.checkPasswordSpecialChar(),
                passwordsMatchs: this.checkPasswordsMatchs()
            }, () => {
                this.setState({
                    passwordValidity: this.checkPasswordValidity()
                })
            })
        })
    }

    handlePasswordConfirmChange(e) {
        this.setState({
            passwordConfirm: e.target.value
        }, () => {
            this.setState({
                passwordLength: this.checkPasswordLength(),
                passwordNumber: this.checkPasswordNumber(),
                passwordLowerCase: this.checkPasswordLowerCase(),
                passwordUppercase: this.checkPasswordUppercase(),
                passwordSpecialChar: this.checkPasswordSpecialChar(),
                passwordsMatchs: this.checkPasswordsMatchs()
            }, () => {
                this.setState({
                    passwordValidity: this.checkPasswordValidity()
                })
            })
        })
    }

    async handleFormSubmit() {
        this.props.toggleLoad()
        const response = await Api.user.changeUserPassword(this.state.userId, this.state.currentPassword, this.state.password, Session.getJwtAndRefreshToken())
        if (response.status === 200) {
            this.props.toggleLoad()
        } else {
            console.log("une erreur est survenue")
            this.props.toggleLoad()
        }
        this.props.onCancel()
    }

    checkPasswordLength() {
        return this.state.password.length >= 8
    }

    checkPasswordNumber() {
        return (new RegExp(/[1-9]/)).test(this.state.password)
    }

    checkPasswordLowerCase() {
        return (new RegExp(/[a-z]/)).test(this.state.password)
    }

    checkPasswordUppercase() {
        return (new RegExp(/[A-Z]/)).test(this.state.password)
    }

    checkPasswordSpecialChar() {
        return (new RegExp(/[-_#@$%*!?&]/)).test(this.state.password)
    }

    checkPasswordsMatchs() {
        return this.state.password === this.state.passwordConfirm && this.state.password !== "" && this.state.passwordConfirm !== ""
    }

    checkPasswordValidity() {
        return this.state.passwordLength && this.state.passwordNumber && this.state.passwordLowerCase && this.state.passwordUppercase && this.state.passwordSpecialChar && this.state.passwordsMatchs
    }

    render() {
        return <>
            <h2><FontAwesomeIcon icon="fa-solid fa-shield-halved"/> {i18n.t("parameter.security")}</h2>
            {!this.state.edition
                ? <ButtonPrimary
                    type="button"
                    name="loginButton"
                    className="save-button"
                    onClick={(e) => {
                        this.setState({edition: true})
                        this.props.enable()
                    }}
                    disabled={this.props.disabled}>
                    <FontAwesomeIcon icon="fa-solid fa-key"/> {i18n.t("parameter.changePassword")}
                </ButtonPrimary>
                : <>
                    <div className="password-form">
                        <div className="activate-form">
                            <InputField type="password" name="currentPassword" value={this.state.currentPassword}
                                        title={i18n.t("activate.password.create")} context={this}
                                        onChange={(e) => this.setState({currentPassword: e.target.value})}>
                                {i18n.t("parameter.currentPassword")}
                            </InputField>
                            <InputField type="password" name="newPassword" value={this.state.password}
                                        title={i18n.t("activate.password.create")} context={this}
                                        onChange={this.handlePasswordChange}>
                                {i18n.t("activate.password.create")}
                            </InputField>
                            <InputField type="password" name="passwordConfirm" value={this.state.passwordConfirm}
                                        title={i18n.t("activate.password.confirm")} context={this}
                                        onChange={this.handlePasswordConfirmChange}>
                                {i18n.t("activate.password.confirm")}
                            </InputField>
                        </div>
                        <div className="activate-security">
                            <div>{i18n.t("activate.password.security.label")}</div>
                            <div className="activate-security-requirements">
                            <span className={this.state.passwordLength ? "valid" : "not-valid"}>
                                <FontAwesomeIcon icon="fas fa-circle"/> {i18n.t("activate.password.security.length")}
                            </span>
                                <span className={this.state.passwordLowerCase ? "valid" : "not-valid"}>
                                <FontAwesomeIcon icon="fas fa-circle"/> {i18n.t("activate.password.security.lowercase")}
                            </span>
                                <span className={this.state.passwordUppercase ? "valid" : "not-valid"}>
                                <FontAwesomeIcon icon="fas fa-circle"/> {i18n.t("activate.password.security.uppercase")}
                            </span>
                                <span className={this.state.passwordNumber ? "valid" : "not-valid"}>
                                <FontAwesomeIcon icon="fas fa-circle"/> {i18n.t("activate.password.security.number")}
                            </span>
                                <span className={this.state.passwordSpecialChar ? "valid" : "not-valid"}>
                                <FontAwesomeIcon
                                    icon="fas fa-circle"/> {i18n.t("activate.password.security.specialchar")}
                            </span>
                                <span className={this.state.passwordsMatchs ? "valid" : "not-valid"}>
                                <FontAwesomeIcon icon="fas fa-circle"/> {i18n.t("activate.password.security.match")}
                            </span>
                            </div>
                        </div>
                    </div>

                    <div className="password-buttons">
                        <ButtonPrimary type="button" name="submitButton" title={i18n.t("activate.activate")}
                                       className="submitButton" onClick={this.handleFormSubmit}
                                       disabled={!this.state.passwordValidity}>
                            <FontAwesomeIcon icon="fas fa-unlock"/> <span>{i18n.t("parameter.save")}</span>
                        </ButtonPrimary>
                        <ButtonPrimary type="button" name="loginButton" className="cancel-button"
                                       onClick={this.handleCancel}>
                            <FontAwesomeIcon icon="fa-solid fa-xmark"/> {i18n.t("parameter.cancel")}
                        </ButtonPrimary>
                    </div>
                </>
            }
        </>

    }
}