import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {NavLink, useLocation, useNavigate} from "react-router-dom"
import {ButtonPrimary} from "../general/Button"
import {routes as Routing} from "../../services/RoutesHelper"
import QuizProgressJauge from "./QuizProgressJauge"
import i18n from "i18next"
import Level from "./Level"
import {Api, Session} from "scg.common-library"
import {useEffect, useState} from "react";

function QuizProgression({ allSteps = 10 }) {
    const locationObjects = useLocation()

    const navigate = useNavigate();
    

    const [vars, setVars] = useState(
        {
            quizCount: 0,
            userId: 0,
            points:0,
            experience:1,
            nextLevelName: '',
            currentLevelName : '',
            delay:90
        }
    )
    const [loading, setLoading] = useState(false)
    
    useEffect(() => {
        const cantAccessPage = () => {
            if (!locationObjects?.state?.fromNormalStep || locationObjects?.state?.quizTimeLeft > 0) {
                navigate(Routing.game.path);
            }
        }
        cantAccessPage();
        getData()
    }, [locationObjects?.state, navigate])
    
    
    return (
        <div className="items_group">
            <h1 style={{textAlign: "center"}}>{i18n.t("game.quiz_progress.title")}</h1>

            <div className="spaced-beetwen-contents-center">
                <div className="items_title brighting_text">{i18n.t("game.quiz_progress.sub")}</div>
                <Level level={{rank: vars.experience, name: vars.currentLevelName}}/>
            </div>

            <div className="spaced-beetwen-contents-center" style={{marginTop: 30}}>
                { vars.nextLevelName!=='' && <div style={{display: "flex", alignItems: "center"}}>
                    <FontAwesomeIcon icon="fa-solid fa-crosshairs" size="5x"/>
                    <div
                        className="brighting_text tab-1X">{i18n.t("game.quiz_progress.target", {"next": vars.nextLevelName})}</div>
                </div> }
                <div className="spaced-beetwen-contents-center">
                    <div className="rounded_number brighting_text">{vars.quizCount}</div>
                    <div className="brighting_text tab-1X">{i18n.t("game.quiz_progress.checked")}</div>
                </div>
                <div className="spaced-beetwen-contents-center">
                    <div className="brighting_text">{i18n.t("game.quiz_progress.points")} &nbsp;</div>
                    <div className="rounded_number brighting_text tab-1X">{vars.points}</div>
                    <div className="brighting_text tab-1X">{i18n.t("game.points")}</div>
                </div>
            </div>
            <div style={{marginTop: 30}}>
                <QuizProgressJauge currentValue={vars.quizCount}/>
            </div>
            <div className="brighting_text col-centered" style={{fontSize: 30}}>{i18n.t("game.quiz_progress.realized", {
                current: vars.quizCount%10,
                all: allSteps
            })}</div>

            <div className="col-centered" style={{marginTop: 40}}>
                <div className="spaced-beetwen-contents-center">
                    <NavLink to={Routing.game.path} title={i18n.t("game.quiz_progress.back")}>
                        <ButtonPrimary type="button" name="back">
                            <FontAwesomeIcon icon="fa-solid fa-left-long"
                                             size="1x"/><span>  {i18n.t("game.quiz_progress.back")}</span>
                        </ButtonPrimary>
                    </NavLink>
                    <NavLink style={{pointerEvents: loading ? "none" : "auto" }} to={Routing.quiz_info.path} state={{"delay":vars.delay, "questionNumber":5, "fromNormalStep": locationObjects?.state?.fromNormalStep, "quizTimeLeft": locationObjects?.state?.quizTimeLeft }} title={i18n.t("game.quiz_progress.details")} >
                        <ButtonPrimary type="button" name="go" disabled={loading}>
                            <FontAwesomeIcon icon="fa-solid fa-circle-info"
                                             size="1x"/><span> {i18n.t("game.quiz_progress.details")}</span>
                        </ButtonPrimary>
                    </NavLink>
                </div>
            </div>
        </div>)

    async function getData() {
        setLoading(true)
        const response = await Api.user.getUser(Session.getSessionUser().id, true, Session.getJwtAndRefreshToken())
        if (response.status===200){
            const nextResponse = await Api.level.getExperience(response.data.user.currentExperience)
            if (nextResponse.status===200) {
                setVars({
                    quizCount: response.data.user.quizCount,
                    points: response.data.user.points,
                    experience: response.data.user.currentExperience,
                    nextLevelName: nextResponse.data.nextLevelName !== nextResponse.data.name ? nextResponse.data.nextLevelName : '',
                    currentLevelName: nextResponse.data.name,
                    delay:response.data.user.society.parameter.timeLimitQuestion
                })
                setLoading(false)
            }
        } 

    }
}

export default QuizProgression