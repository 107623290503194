import {useEffect, useState} from "react"
import i18n from "i18next"
import {Api, Session} from "scg.common-library"
import Helper from "../services/Helper"
import {routes as Routing} from "../services/RoutesHelper"

/* ********************************* HOOKS ********************************* */

/**
 * custom Hook pour centraliser la logique du code
 * @param startValue {number}
 * @param step {number}
 * @return {(number|increment)[]}
 */
function useIncrement(startValue = 0, step = 1) {
    const [count, setCount] = useState(startValue)

    const increment = () => {
        setCount(c => c + step)
    }
    return [count, increment]
}

/**
 * custom Hook pour centraliser la logique du code
 * @param initial {number}
 * @param step {number}
 * @param timeout {number}
 * @return {(number|increment)[]}
 */
function useAutoIncrement(initial = 0, step = 1, timeout = 1000) {
    const [count, increment] = useIncrement(initial, step)

    // Equivalent du ComponentDidMount
    useEffect(() => {
        const timer = window.setInterval(() => {
            increment()
        }, timeout)

        // Fonction de retour utilisée lors du démontage du composant
        return function () {
            clearInterval(timer)
        }
    })

    return [count, increment]
}

/**
 *
 * @param initial {boolean}
 * @return {(boolean|toggle)[]}
 */
function useToggle(initial = false) {
    const [state, toggleState] = useState(initial)

    const toggle = () => {
        toggleState(prevState => !prevState)
    }
    return [state, toggle]
}

/**
 *
 * @param endPoint
 * @param method
 * @param postData
 * @return {(boolean|[])[]}
 */
function useAjax(endPoint, method = "GET", postData = {}) {
    const [state, setState] = useState({
        result: {}, loading: true
    })
    useEffect(() => {
        // Evite de retourner une promesse avec l'utilisatation de await
        (async function () {
            Api.requestApi(endPoint, method, postData, Session.getJwtAndRefreshToken()).then(response => {
                const data = Helper.isValidResponse(response)
                if (response.status === 200) {
                    setState({result: data, loading: false})
                } else {
                    setState(prevState => ({...prevState, loading: false}))
                }
            }).catch((error) => {
                console.log(error)
                Helper.displayMessage(i18n.t("error.generic"), "error")
            })
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (endPoint === "") {
        return [null, null]
    }

    return [state.loading, state.result]
}

/**
 *
 * @param func
 * @param data
 */
function useApi(func, data = {}) {
    const [result, setResult] = useState({})
    useEffect(() => {
        (async function () {
            func(data, Session.getJwtAndRefreshToken()).then(response => {
                const r = Helper.isValidResponse(response)
                setResult(r)
            }).catch((error) => {
                console.log(error)
                Helper.displayMessage(i18n.t("error.generic"), "error")
            })
        })()
    }, [func, data])
    return result
}

/**
 *
 * @return {{}}
 */
function useCurrentUser() {
    const [state, setState] = useState({
        result: {}, loading: true
    })
    useEffect(() => {
        (async function () {
            Api.user.checkUserJWT(Session.getJwtToken(), Session.getJwtAndRefreshToken()).then((response) => {
                const data = Helper.isValidResponse(response)
                if (response.status === 200) {
                    setState({result: data, loading: false})
                } else if (state.loading === true && response?.code === 401) {
                    //redirect to home page to login
                    setState({result: null, loading: false})
                    Session.removeJwtToken()
                    window.location.href = Routing.app_login.path
                } else {
                    console.log(response)
                    Helper.displayMessage(i18n.t("error.generic"), "error", 3000, "colored")
                    setState(prevState => ({...prevState, loading: false}))
                }
            }).catch((error) => {
                console.log(error)
                Helper.displayMessage(i18n.t("error.generic"), "error", false, "colored")
                //TODO: Display an error page instead of an infinite loading screen ?
            })

        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return [state.loading, state.result]
}

export {
    useAjax, useApi, useCurrentUser, useToggle, useIncrement, useAutoIncrement
}