import React from "react"
import {ButtonPrimary} from "../general/Button"
import {NavLink} from "react-router-dom"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {routes as Routing} from "../../services/RoutesHelper"
import i18n from "i18next"

export class UserPoints extends React.Component {
    static defaultProps = {
        user: null
    }

    constructor(props) {
        super(props)

        this.state = {
            currentPoints: 0
        }
    }

    componentDidMount() {
        this.setState({
            currentPoints: this.props.user.points
        })
    }

    render() {
        return <div className="user-points">
            <NavLink to={Routing.history.path} title={i18n.t("game.history")}>
                <ButtonPrimary type="button" name="pointsButton">
                    <FontAwesomeIcon icon="fas fa-star"/>
                </ButtonPrimary>
                <div className="user-currentPoints">{this.state.currentPoints} {i18n.t("game.points")}</div>
            </NavLink>
        </div>
    }
}