import React from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {Api, Session} from "scg.common-library"
import {ButtonPrimary} from "../general/Button"
import {InputField} from "../general/Input"
import OutputField from "../general/OutputField"
import i18n from "i18next"
import Helper from "../../services/Helper"

import "./profileParameters.css"

export default class ProfileParameters extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            initialUser: this.props.user,
            currentUser: this.props.user,
            loading: false,
            edition: false
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleCancel = this.handleCancel.bind(this)
        this.handleSave = this.handleSave.bind(this)
        this.toggleLoad = this.props.toggleLoad ?? (() => this.setState({loading: !this.state.loading}))
    }

    componentDidMount() {
    }

    handleChange(e) {
        let value = null
        e.target.type === "checkbox" ? value = e.target.checked : value = e.target.value
        const state = this.state
        state.currentUser[e.target.name] = value
        this.setState(state)
    }

    handleCancel() {
        this.setState({
            currentUser: this.state.initialUser,
            edition: false
        })
        this.props.onCancel()
    }

    async handleSave() {
        this.props.toggleLoad()
        const userObject = {
            pseudo: this.state.currentUser.pseudo,
            name: this.state.currentUser.name,
            surname: this.state.currentUser.surname,
            acceptNotification: this.state.currentUser.acceptNotification
        }

        //TODO: Mettre à jour le cookie de session avec le nouveau nom de l'utilisateur
        const response = await Api.user.updateUser(this.state.currentUser.id, userObject, Session.getJwtAndRefreshToken())
        const result = Helper.isValidResponse(response)
        if (!result) {
            //TODO: afficher un message d'erreur à l'utilisateur avec la raison précise de l'echec
            Helper.displayMessage(i18n.t("error.generic"), 'error')
        }
        this.props.toggleLoad()
        this.props.onCancel()
    }

    render() {
        return <>
            {this.setState.loading}
            <h2><FontAwesomeIcon icon="fa-regular fa-user"/> {i18n.t("parameter.profile")}</h2>
            <div className="parameter-profil">

                {/* TODO: get avatar from user profile */}
                <img id="avatar" src="/img/avatar-small.png" alt="user avatar"/>

                {!this.state.edition
                    ? <div className="parameter-profil-data">
                        <OutputField name="society" value={this.state.currentUser.society.name}>
                            {i18n.t("parameter.form.society")}
                        </OutputField>
                        <OutputField name="email" value={this.state.currentUser.email}>
                            {i18n.t("parameter.form.mail")}
                        </OutputField>
                        <OutputField name="pseudo" value={this.state.currentUser.pseudo}>
                            {i18n.t("parameter.form.pseudo")}
                        </OutputField>
                        <OutputField name="name" value={this.state.currentUser.name}>
                            {i18n.t("parameter.form.name")}
                        </OutputField>
                        <OutputField name="surname" value={this.state.currentUser.surname}>
                            {i18n.t("parameter.form.surname")}
                        </OutputField>
                        <OutputField name="surname"
                                     value={this.state.currentUser.acceptNotification !== true ? i18n.t("parameter.no") : i18n.t("parameter.yes")}>
                            {i18n.t("parameter.form.acceptNotification")}
                        </OutputField>

                    </div>
                    : <div className="parameter-profil-data editing">
                        <InputField name="pseudo" value={this.state.currentUser.pseudo} onChange={this.handleChange}
                                    isFocused={true}>
                            {i18n.t("parameter.form.pseudo")}
                        </InputField>
                        <InputField name="name" value={this.state.currentUser.name} onChange={this.handleChange}
                                    isFocused={true}>
                            {i18n.t("parameter.form.name")}
                        </InputField>
                        <InputField name="surname" value={this.state.currentUser.surname}
                                    onChange={this.handleChange}
                                    isFocused={true}>
                            {i18n.t("parameter.form.surname")}
                        </InputField>
                        <InputField type="checkbox" name="acceptNotification"
                                    checked={this.state.currentUser.acceptNotification}
                                    onChange={this.handleChange}
                                    isFocused={true}>
                            {i18n.t("parameter.form.acceptNotification")}
                        </InputField>
                    </div>
                }
            </div>

            {!this.state.edition
                ? <div className="parameter-profil-button">
                    <ButtonPrimary type="button" name="loginButton" disabled={this.props.disabled}
                                   onClick={() => {
                                       this.setState({edition: true})
                                       this.props.enable()
                                   }}>
                        <FontAwesomeIcon icon="fa-regular fa-pen-to-square"/> {i18n.t("parameter.editProfile")}
                    </ButtonPrimary>
                </div>
                : <div className="parameter-profil-button">
                    <ButtonPrimary type="button" name="loginButton" className="save-button"
                                   onClick={this.handleSave}>
                        <FontAwesomeIcon icon="fa-regular fa-floppy-disk"/> {i18n.t("parameter.save")}
                    </ButtonPrimary>
                    <ButtonPrimary type="button" name="loginButton" className="cancel-button"
                                   onClick={this.handleCancel}>
                        <FontAwesomeIcon icon="fa-solid fa-xmark"/> {i18n.t("parameter.cancel")}
                    </ButtonPrimary>
                </div>
            }
        </>

    }
}