import i18n from "i18next"
import {SimpleButton} from "../general/Button"

import "./home.css"

export default function HomePage() {
    return <>
        <section className="home-title" id="top">
            <div className="home-left">
                <h1 className="title">{i18n.t("home.title")}</h1>
                <div className="subtitle">{i18n.t("home.subtitle")}</div>
                <div className="summary">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed fringilla dolor. Donec blandit
                    urna
                    sapien, id porta est sodales eget.
                    <br/>
                    Nam accumsan enim ultrices, pretium orci non, egestas diam. Aenean venenatis malesuada nibh ut
                    mollis.
                    <br/>
                    Aliquam malesuada massa sed risus laoreet, ac placerat lectus volutpat. Donec ullamcorper tempus
                    lacus sit amet vestibulum.
                    <br/>
                    <SimpleButton type="button" name="seemore" className="seemore-button">
                        <a href="#features" title={i18n.t("home.seeMore")}>{i18n.t("home.seeMore")}</a>
                    </SimpleButton>
                </div>
            </div>
            <div className="home-right">
                <img src="/img/illustration.png" alt="" title=""/>
            </div>
        </section>

        <section className="features" id="features">
            <div>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras iaculis arcu vel ornare efficitur. Morbi
                dolor arcu, eleifend eu dui quis, pulvinar laoreet ipsum. Praesent sed orci laoreet, elementum mauris
                sagittis, dapibus urna. Donec gravida sodales ligula, eget sollicitudin ligula. Praesent eget nibh
                faucibus, scelerisque tellus vitae, condimentum augue. Vestibulum ultricies, tellus quis feugiat
                bibendum, risus arcu tristique mi, et congue mi nulla eu mauris. Suspendisse a ipsum ante. Pellentesque
                pulvinar orci et nisl auctor tempor. Ut metus est, vestibulum euismod lorem sit amet, ornare euismod
                dolor.
            </div>
            <div>
                Etiam luctus nibh nunc, vel maximus felis consequat ac. Pellentesque sed massa dignissim, sodales enim
                a, fringilla turpis. Nunc finibus justo erat, in tincidunt justo tempus vel. In sit amet convallis
                felis, id ultrices elit. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere
                cubilia curae; Nulla condimentum volutpat velit, ut tristique velit ultricies nec. Morbi a faucibus
                lacus. Donec fermentum, mauris sodales aliquet aliquet, ligula mauris convallis enim, et eleifend nulla
                dui ut ante. Duis sodales quam sed turpis tristique, vel pellentesque tortor mollis. Nulla mollis ex
                nunc, sit amet hendrerit lectus imperdiet in. Donec lacinia pretium diam, at hendrerit diam finibus ut.
                Integer vitae eros laoreet, volutpat mi in, sagittis tortor. Praesent non ipsum eu erat euismod commodo.
                Suspendisse vel tellus vestibulum, ullamcorper odio ac, hendrerit arcu. Etiam accumsan erat ut lorem
                consectetur, pellentesque luctus ex auctor.
            </div>
        </section>

        <section className="pricing" id="pricing">
            <div>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras iaculis arcu vel ornare efficitur. Morbi
                dolor arcu, eleifend eu dui quis, pulvinar laoreet ipsum. Praesent sed orci laoreet, elementum mauris
                sagittis, dapibus urna. Donec gravida sodales ligula, eget sollicitudin ligula. Praesent eget nibh
                faucibus, scelerisque tellus vitae, condimentum augue. Vestibulum ultricies, tellus quis feugiat
                bibendum, risus arcu tristique mi, et congue mi nulla eu mauris. Suspendisse a ipsum ante. Pellentesque
                pulvinar orci et nisl auctor tempor. Ut metus est, vestibulum euismod lorem sit amet, ornare euismod
                dolor.
            </div>
            <div>
                Etiam luctus nibh nunc, vel maximus felis consequat ac. Pellentesque sed massa dignissim, sodales enim
                a, fringilla turpis. Nunc finibus justo erat, in tincidunt justo tempus vel. In sit amet convallis
                felis, id ultrices elit. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere
                cubilia curae; Nulla condimentum volutpat velit, ut tristique velit ultricies nec. Morbi a faucibus
                lacus. Donec fermentum, mauris sodales aliquet aliquet, ligula mauris convallis enim, et eleifend nulla
                dui ut ante. Duis sodales quam sed turpis tristique, vel pellentesque tortor mollis. Nulla mollis ex
                nunc, sit amet hendrerit lectus imperdiet in. Donec lacinia pretium diam, at hendrerit diam finibus ut.
                Integer vitae eros laoreet, volutpat mi in, sagittis tortor. Praesent non ipsum eu erat euismod commodo.
                Suspendisse vel tellus vestibulum, ullamcorper odio ac, hendrerit arcu. Etiam accumsan erat ut lorem
                consectetur, pellentesque luctus ex auctor.
            </div>
        </section>

        <section className="contact" id="contact">
            <div>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras iaculis arcu vel ornare efficitur. Morbi
                dolor arcu, eleifend eu dui quis, pulvinar laoreet ipsum. Praesent sed orci laoreet, elementum mauris
                sagittis, dapibus urna. Donec gravida sodales ligula, eget sollicitudin ligula. Praesent eget nibh
                faucibus, scelerisque tellus vitae, condimentum augue. Vestibulum ultricies, tellus quis feugiat
                bibendum, risus arcu tristique mi, et congue mi nulla eu mauris. Suspendisse a ipsum ante. Pellentesque
                pulvinar orci et nisl auctor tempor. Ut metus est, vestibulum euismod lorem sit amet, ornare euismod
                dolor.
            </div>
            <div>
                Etiam luctus nibh nunc, vel maximus felis consequat ac. Pellentesque sed massa dignissim, sodales enim
                a, fringilla turpis. Nunc finibus justo erat, in tincidunt justo tempus vel. In sit amet convallis
                felis, id ultrices elit. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere
                cubilia curae; Nulla condimentum volutpat velit, ut tristique velit ultricies nec. Morbi a faucibus
                lacus. Donec fermentum, mauris sodales aliquet aliquet, ligula mauris convallis enim, et eleifend nulla
                dui ut ante. Duis sodales quam sed turpis tristique, vel pellentesque tortor mollis. Nulla mollis ex
                nunc, sit amet hendrerit lectus imperdiet in. Donec lacinia pretium diam, at hendrerit diam finibus ut.
                Integer vitae eros laoreet, volutpat mi in, sagittis tortor. Praesent non ipsum eu erat euismod commodo.
                Suspendisse vel tellus vestibulum, ullamcorper odio ac, hendrerit arcu. Etiam accumsan erat ut lorem
                consectetur, pellentesque luctus ex auctor.
            </div>
        </section>
    </>
}
