import {Constants} from 'scg.common-library'

const {Howl, Howler} = require("howler")

/* ============================== Sound controls ============================== */
/**
 * Play a sound in the user browser
 *
 * @param soundPath {string} name of the sound file
 * @param title {string} An unique string used to identified the sound
 * @param type {string|null} can be "music", "sounds" or "dialog". The type is used to categorize the sound in a specific sountrack
 * @param loop {boolean} Set to true to automatically loop the sound forever
 * @param volume {number|null} Set the volume from 0.0 to 1.0, you must set `type` parameter to null to override the defaultVolume
 * @param autoplay {boolean} Set to true to automatically start playback when sound is loaded.
 * @return {Number} Unique identifier associated to the sound
 */
function playSound(soundPath, title, type = null, loop = false, volume = null, autoplay = true) {
    soundPath = `${process.env.PUBLIC_URL}/audio/${soundPath}`
    /*le volume generale qui doit être considéré en premier*/
    const defaulGeneralVolume= getDefaultVolumes("general") ?? 1
    /*l'autre type de volume vient en consideration en deuxième parametre*/
    const otherVolume = type==="general" ? 1 : (getDefaultVolumes(type) ?? 0)

    const defaultVolume = defaulGeneralVolume * otherVolume
    //const defaultVolume = getDefaultVolumes((type === null) ? "general" : type)
    volume = volume ?? defaultVolume

    const sound = new Howl({
        src: [soundPath],
        autoplay: autoplay,
        loop: loop,
        volume: volume
    })

    // Play the sound.
    const soundID = sound.play()
    addPlayingSound(title, soundID, type)

    // Fired when the sound finishes playing.
    sound.on("end", function () {
        removePlayingSound(type, soundID)
        // console.log('Sound finished!');
    })
    return soundID
}

/**
 * Mute or unmute the global volume
 *
 * @param state {boolean} Set to true to mute the global volume
 */
function muteGlobalVolume(state = true) {
    Howler.mute(state)
    // (state)? Howler.stop() : playBackgroundMusic()
    // console.log(`Muted : ${state}`)
}

/**
 * Define the global volume
 *
 * @param percent {float} The volume of the global soundtrack, from 0.0 to 1.0
 * @return {Howler} self Howler object
 */
function setGlobalVolume(percent) {
    return Howler.volume(percent)
}

/**
 * Get the current global volume
 *
 * @return {Float} current volume
 */
function getCurrentVolume() {
    return Howler.volume()
}

/**
 * Retrieve all currently playing sounds
 *
 * @return {array} list of current sounds playing (title and unique id) ordered by type
 */
function getCurrentPlayingSounds() {
    return JSON.parse(localStorage.getItem(`${Constants.PREFIX_LOCAL_STORAGE}-playingSounds`))
}

/**
 *  Retrive default volumes for all soundtrack types
 *
 * @param type {string|null} set a type name to get only volumes for this specific type
 * @return {null|array} null if type is unknown, array of volumes otherwise
 */
function getDefaultVolumes(type = null) {
    let defaults = JSON.parse(localStorage.getItem(`${Constants.PREFIX_LOCAL_STORAGE}-defaultSoundsVolume`))
    if (type !== null) {
        if (defaults.hasOwnProperty(type)) {
            defaults = defaults[type]
        } else {
            return null
        }
    }
    return defaults
}

/**
 * Add a sound to the current playing sounds list
 *
 * @param title {string} Title of the sound, usually the file name
 * @param type {string|null} Can be “music”, “sounds” or “dialog”. The type is used to categorize the sound in a specific sountrack
 * @param soundId {number} A unique Id generated at the sound creation
 * @return {any}
 */
function addPlayingSound(title, soundId, type = null ) {
    let playingSounds = getCurrentPlayingSounds()
    if (type !== null && playingSounds.hasOwnProperty(type)) {
        playingSounds[type].push({title: title, id: soundId})
    } else {
        playingSounds["other"].push({title: title, id: soundId})
    }
    localStorage.setItem(`${Constants.PREFIX_LOCAL_STORAGE}-playingSounds`, JSON.stringify(playingSounds))
    return playingSounds
}

/**
 * Remove a sound from the current playing sounds list
 *
 * @param type {string} Can be “music”, “sounds” or “dialog”. The type is used to categorize the sound in a specific sountrack
 * @param soundId {number} A unique Id generated at the sound creation
 */
function removePlayingSound(type = "", soundId) {
    let playingSounds = getCurrentPlayingSounds()
    if (playingSounds.hasOwnProperty(type)) {
        const foundIndex = playingSounds[type].findIndex(object => {
            return object.id === soundId
        })
        if (foundIndex !== -1) {
            playingSounds[type].splice(foundIndex, 1)
            localStorage.setItem(`${Constants.PREFIX_LOCAL_STORAGE}-playingSounds`, JSON.stringify(playingSounds))
        }
    }
    return playingSounds
}

/**
 * Remove all sounds from the playing sounds list
 *
 * @param type {string|null} Can be “music”, “sounds”, “dialog” or "ALL"
 */
function removeAllPlayingSounds(type = null) {
    let playingSounds = getCurrentPlayingSounds()
    if (playingSounds.hasOwnProperty(type)) {
        playingSounds[type] = []
    }
    if (type === "ALL") {
        Object.entries(playingSounds).forEach((key, value) => {
            playingSounds[key] = []
        })
    }
    localStorage.setItem(`${Constants.PREFIX_LOCAL_STORAGE}-playingSounds`, JSON.stringify(playingSounds))
    return playingSounds
}

/**
 * Set the default volume for each soundtrack passed in the `volumes` object
 *
 * @param volumes {Object} Object format must be something like {"music":0.5,"general":0.8}
 */
function setDefaultVolumes(volumes) {
    let defaultVolumes = JSON.parse(localStorage.getItem(`${Constants.PREFIX_LOCAL_STORAGE}-defaultSoundsVolume`))

    if (volumes.hasOwnProperty("music")) {
        defaultVolumes.music = parseFloat(volumes.music)
    }
    if (volumes.hasOwnProperty("sounds")) {
        defaultVolumes.sounds = parseFloat(volumes.sounds)
    }
    if (volumes.hasOwnProperty("dialog")) {
        defaultVolumes.dialog = parseFloat(volumes.dialog)
    }
    if (volumes.hasOwnProperty("general")) {
        defaultVolumes.general = parseFloat(volumes.general)
    }
    localStorage.setItem(`${Constants.PREFIX_LOCAL_STORAGE}-defaultSoundsVolume`, JSON.stringify(defaultVolumes))

    return defaultVolumes
}

/**
 * Update the volume for all `music` type soundtrack
 *
 * @param volume {float} volume level from 0.0 to 1.0
 */
function updateMusicVolume(volume) {
    updateVolumeForSoundtrack(volume, "music")
}

/**
 * Update the volume for all `dialog` type soundtrack
 *
 * @param volume {float} volume level from 0.0 to 1.0
 */
function updateDialogVolume(volume) {
    updateVolumeForSoundtrack(volume, "dialog")

}

/**
 *
 * @param volume {float} volume level from 0.0 to 1.0
 * @param type {string} Can be “music”, “sounds” or “dialog”. The type is used to target a specific sountrack
 */
function updateVolumeForSoundtrack(volume, type){
    let playingSounds = getCurrentPlayingSounds()
    if (playingSounds.hasOwnProperty(type)) {
        Object.entries(playingSounds[type]).forEach(([key, soundId]) => {
            Howler.volume(volume, soundId)
        })
    }
}

const exportedFunctions = {
    setDefaultVolumes,
    addPlayingSound,
    getDefaultVolumes,
    getCurrentPlayingSounds,
    getCurrentVolume,
    setGlobalVolume,
    muteGlobalVolume,
    playSound,
    updateMusicVolume,
    updateDialogVolume,
    removePlayingSound,
    removeAllPlayingSounds
}
export default exportedFunctions