import React from "react"
import "./output.css"

export default class OutputField extends React.Component {

    render() {
        const {children, name, value, title, forwardedRef} = this.props
        return (
            <>
                <label htmlFor={name}>{children}</label>
                <output
                    id={name}
                    name={name}
                    title={title}
                    ref={forwardedRef}>
                        {value}
                </output>
            </>
        )
    }
}