import React from "react"
import {NavLink} from "react-router-dom"
import {routes as Routing} from "../../services/RoutesHelper"
import {Api} from "scg.common-library"
import {LoadingOverlay} from "../general/Loading"
import Helper from "../../services/Helper"
import {ButtonPrimary} from "../general/Button"
import i18n from "i18next"
import Modal from "../general/Modal"
import QuizFeedBack from "./QuizFeedBack"
import CorrectionDetailsPage from "./CorrectionDetailsPage";
import "./quizCorrection.css"

export default class CorrectionPage extends React.Component {
    static defaultProps = {
        quizId: null,
        data: []
    }

    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            quiz: null,
            quizFeedbackDisplay: true,
            resume: {},
            correction: false
        }
        this._handleModalClose = this._handleModalClose.bind(this)
        this._displayModal = this._displayModal.bind(this)
        this._failedFeedback = this._failedFeedback.bind(this)
        this._sucessFeedback = this._sucessFeedback.bind(this)
        this._handleCorrection = this._handleCorrection.bind(this)
    }

    _handleCorrection() {
        this.setState(prevState => {
            return {correction: !prevState.correction}
        })
    }

    _displayModal() {
        this.setState({quizFeedbackDisplay: true})
    }

    _handleModalClose() {
        this.setState({quizFeedbackDisplay: false})
    }

    _sucessFeedback() {
        this._handleModalClose()
        Helper.displayMessage(i18n.t("game.quiz_feedback.thanks"), 'success', 2000, "colored")
    }

    _failedFeedback() {
        Helper.displayMessage(i18n.t("error.generic"), 'error', 2000, "colored")
    }

    componentDidMount() {
        Api.quiz.getQuiz(this.props.quizId).then((r) => {
            const quiz = Helper.isValidResponse(r)
            this.setState({loading: false, quiz: quiz})
        })

        this.getResume(Routing.quiz_correction.answers)
    }

    async getResume() {
        const response = await Api.quiz.getQuizAfterSubmittedResume(this.props.quizId, this.props.data.answers, 1)
        if (response.status === 200) {
            response.data = await response.json()
            response.data.total=parseInt(response.data.correctCount)+parseInt(response.data.incorrectCount)+parseInt(response.data.notRespondedCount)+parseInt(response.data.partialCorrectCount)
            response.data.completionPercent=((Math.round((parseInt(response.data.correctCount)/response.data.total === 0 ? 1 : response.data.total)*10000))/10000) *100
            this.setState({resume:response.data})
        }
    }

    render() {
        return <div style={{position: "relative", height: "100%"}}>
            {this.state.loading && <LoadingOverlay/>}

            {this.state.correction && <CorrectionDetailsPage quizId={this.props.quizId} handleClose={this._handleCorrection} />}

            <Modal title={i18n.t("game.quiz_feedback.title")} hide={this._handleModalClose}
                   isShowing={this.state.quizFeedbackDisplay}>
                <div className="quiz-feedback">
                    <QuizFeedBack quizId={this.props.quizId} callbackSuccess={this._sucessFeedback} callbackFailed={this._failedFeedback} callbackSkipping={this._handleModalClose}/>
                </div>
            </Modal>

            <section className="quiz-correction">
                <div className="title-wrapper">
                    <h2 className="title">{i18n.t("game.quiz_correction.title")}</h2>
                </div>
                <div className="correction-container">
                    <div className="correction-left">
                        <div className="points" title={i18n.t("game.quiz_correction.score")}>
                            <img src="/img/interface/quiz/icon-score.png" alt={i18n.t("game.quiz_correction.score")}
                                 className="points-img"/>
                            <div className="points-text">
                                <span>{this.state.resume?.points<0? this.state.resume?.points : '+'+ this.state.resume?.points } <span className="label">{i18n.t("game.points")}</span></span>
                            </div>
                        </div>
                        <div className="bonus-points" title={i18n.t("game.quiz_correction.time")}>
                            <img src="/img/interface/quiz/icon-time.png" alt={i18n.t("game.quiz_correction.time")}
                                 className="points-img"/>
                            <div className="points-text">
                                <span>+ {this.state.resume?.bonus} <span className="label">{i18n.t("game.points")}</span></span>
                            </div>
                        </div>
                        <div className="share" title={i18n.t("game.quiz_correction.share")}>
                            <img src="/img/interface/quiz/icon-share.png" alt={i18n.t("game.quiz_correction.share")}
                                 className="points-img"/>
                            <div className="points-text">
                                <span>{i18n.t("game.quiz_correction.share")}</span>
                            </div>
                        </div>
                    </div>
                    <div className="correction-right">
                        <div className="description">
                            <span>{i18n.t("game.quiz_correction.completionPercent", {completionPercent: this.state.resume?.completionPercent})}</span>
                            <span>{i18n.t("game.quiz_correction.correctCount", {correctCount: this.state.resume?.correctCount})}</span>
                            <span>{i18n.t("game.quiz_correction.partiallyCorrectCount", {partiallyCorrectCount: this.state.resume?.partialCorrectCount})}</span>
                            <span>{i18n.t("game.quiz_correction.incorrectCount", {incorrectCount: this.state.resume?.incorrectCount})}</span>
                            <span>{i18n.t("game.quiz_correction.currentLevel", {level: this.state.resume?.level?.name})}</span>
                            <span>{i18n.t("game.quiz_correction.nextLevel", {level: this.state.resume?.level?.nextLevelName})}</span> {/* TODO: récupérer le next level*/}
                        </div>
                        <div className="rank">
                            <span>W.I.P</span>
                            <span>W.I.P</span>
                            <span>W.I.P</span>
                            <span>W.I.P</span>
                        </div>
                    </div>
                </div>
                <div className="correction-footer">
                    <ButtonPrimary onClick={this._handleCorrection} type="button" name="correction">
                        <span>{i18n.t("game.quiz_correction.seeCorrection")}</span>
                    </ButtonPrimary>
                    <NavLink to={Routing.game.path} title="Accéder aux badges">
                    <ButtonPrimary type="button" name="menu">
                        <span>{i18n.t("game.quiz_correction.backMenu")}</span>
                    </ButtonPrimary>
                    </NavLink>
                </div>
            </section>
        </div>
    }
}
