import React from "react"
import ReactDOM from "react-dom/client"
import {BrowserRouter} from "react-router-dom"
import {Constants} from "scg.common-library"
import App from "./App"

import "./web/style/index.css"
import "./web/style/colors.css"

function Root() {
    return <BrowserRouter>
        <App/>
    </BrowserRouter>
}

/* ============================== Main Code ============================== */
const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(<Root/>)

initLocalStorage()

/* ============================== Other Functions ============================== */

function initLocalStorage() {
    localStorage.setItem(`${Constants.PREFIX_LOCAL_STORAGE}-playingSounds`, JSON.stringify({
        "other": [],
        "music": [],
        "sounds": [],
        "dialog": []
    }))
    let defaultSoundsVolume = localStorage.getItem(`${Constants.PREFIX_LOCAL_STORAGE}-defaultSoundsVolume`)
    if (defaultSoundsVolume === null) {
        localStorage.setItem(`${Constants.PREFIX_LOCAL_STORAGE}-defaultSoundsVolume`, JSON.stringify({
            general: 0.8,
            music: 0.2,
            sounds: 0.6,
            dialog: 0.5
        }))
    }
}