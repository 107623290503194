import "./badge.css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import { NavLink} from "react-router-dom"
import {ButtonPrimary} from "../general/Button"
import {routes as Routing} from "../../services/RoutesHelper"

export default function BadgePage() {
    return <>
        <h1>Page des Badges</h1>
        <NavLink to={Routing.game.path} /*className="nav-item" title="Connexion" onClick={handleLoginClick}*/>
            <ButtonPrimary type="button" name="loginButton">
                <FontAwesomeIcon icon="fas fa-right-to-bracket"/> Retour
            </ButtonPrimary>
        </NavLink>
    </>

}