import React from "react"
import i18n from "i18next"
import {Link, NavLink} from "react-router-dom"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {Constants, Session} from "scg.common-library"
import {routes as Routing} from "../../services/RoutesHelper"

import "./header.css"

export default class Header extends React.Component {
    static defaultProps = {
        isLogged: false,
        user: null
    }

    constructor(props) {
        super(props)
        this.state = {
            loggedIn: this.props.isLogged
        }

        this.disconnect = this.disconnect.bind(this)
    }

    componentDidMount() {
        this.setState({loggedIn: this.props.isLogged})
    }

    disconnect() {
        Session.clearSessionAndRedirectToHome()
        this.setState({loggedIn: false})
    }

    render() {
        return <div className="header-menu">
            <div className="logo">
                <a href={`${Routing.app_home.path}#top`} title={i18n.t("home.title")}>
                    <img src="/logo192.png" title={Constants.DOCUMENT_TITLE_FRONTOFFICE} alt={`${i18n.t("home.title")} logo`}/>
                </a>
            </div>
            <div className="menu">
                <a href={`${Routing.app_home.path}#features`} title={i18n.t("home.features")}>{i18n.t("home.features")}</a>
                <a href={`${Routing.app_home.path}#pricing`} title={i18n.t("home.pricing")}>{i18n.t("home.pricing")}</a>
                <a href={`${Routing.app_home.path}#contact`} title={i18n.t("home.contact")}>{i18n.t("home.contact")}</a>
            </div>
            <div className="user">
                {Session.isLoggedIn()
                    ? <>
                        <div className="user-avatar-small"><img src="/img/avatar-small.png" alt="User small avatar"/></div>
                        <span>{i18n.t("home.hello")} {Session.getSessionUser().pseudo},</span>
                        <NavLink to={Routing.game.path} reloadDocument className="nav-item" title={i18n.t("home.accessGame")}>
                            <FontAwesomeIcon icon="fas fa-gamepad"/> {i18n.t("home.accessGame")}
                        </NavLink>
                    </>
                    : <>
                        <Link reloadDocument to={Routing.app_login.path} className="nav-item" title={i18n.t("home.signIn")}>
                            <FontAwesomeIcon icon="fas fa-right-to-bracket"/> {i18n.t("home.signIn")}
                        </Link>
                    </>
                }
            </div>
        </div>
    }
}