import React from "react"
import "./login.css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {Navigate, NavLink} from "react-router-dom"
import {ButtonPrimary} from "../general/Button"
import {Api, Constants, Session} from "scg.common-library"
import {LoadingOverlay} from "../general/Loading"
import i18n from "i18next"
import {InputField} from "../general/Input"
import {routes as Routing} from "../../services/RoutesHelper"
import Helper from "../../services/Helper"

export default class Login extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isSubmited: false,
            isRequestDone: false,
            identifier: "",
            password: "",
            error: {
                status: false,
                message: ""
            },
            notification: {
                status: false,
                message: ""
            }
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleForgottenPassword = this.handleForgottenPassword.bind(this)
        this.handlePasswordChange = this.handlePasswordChange.bind(this)
        this.handleIdentifierChange = this.handleIdentifierChange.bind(this)
    }

    async handleSubmit(e) {
        e.preventDefault()
        if (this.state.identifier === "" || this.state.password === "") {
            this.setState({
                error: {
                    status: true,
                    message: "loginForm.identifierAndPasswordRequired"
                },
                notification: {
                    status: false
                }
            })
        } else {
            this.setState({isSubmited: true})
            Api.user.authenticateUser(this.state.identifier, this.state.password).then(response => {
                if (response.status === 200) {
                    const data = response.data
                    const user = {
                        id: data.id,
                        email: data.email,
                        pseudo: data.pseudo,
                        name: data.name,
                        surname: data.surname
                    }
                    Session.handleLogin({user: user, jwtToken: data.token, refreshToken: data.refreshToken})
                    this.setState({
                        isRequestDone: true,
                        isSubmited: false
                    })
                } else {
                    let message
                    if (response.code === Constants.HTTP_USER_NOT_FOUND) {
                        message = "loginForm.identifier.userNotFound"
                    } else if (response.code === Constants.HTTP_INVALID_PASSWORD) {
                        message = "loginForm.password.invalid"
                    } else if (response.code === Constants.HTTP_INVALID_TOKEN) {
                        message = "loginForm.invalidToken"
                    } else if (response.code === Constants.HTTP_TOKEN_EXPIRED) {
                        message = "loginForm.tokenExpired"
                    }  else if (response.code === Constants.HTTP_BANNED_USER) {
                       message = "loginForm.bannedAccount"
                    } else {
                        message = response.message
                    }
                    this.setState({
                        isRequestDone: false,
                        isSubmited: false,
                        error: {
                            status: true,
                            message: message
                        },
                        notification: {
                            status: false
                        }
                    })
                }
            }).catch((error) => {
                console.log(error)
                Helper.displayMessage(i18n.t("error.generic"), 'error', false)
                this.setState({
                    isRequestDone: false,
                    isSubmited: false
                })
            })

        }
    }

    async handleForgottenPassword(e) {
        e.preventDefault()
        if (this.state.identifier === "") {
            this.setState({
                error: {
                    status: true,
                    message: "loginForm.identifier.required"
                },
                notification: {
                    status: false
                }
            })
        } else {
            this.setState({isSubmited: true})

            const response = await Api.user.checkUserIdentifier(this.state.identifier)

            if (response.status === 200) {
                const data = response.data
                const forgotResponse = await Api.user.userPasswordForgotten(data.id)

                if (forgotResponse.status === 200) {
                    this.setState({
                        isRequestDone: true,
                        isSubmited: false,
                        notification: {
                            status: true,
                            message: "loginForm.password.emailResetPasswordSent"
                        },
                        error: {
                            status: false
                        }
                    })
                } else {
                    this.setState({
                        isRequestDone: false,
                        isSubmited: false,
                        error: {
                            status: true,
                            message: "error"
                        },
                        notification: {
                            status: false
                        }
                    })
                }
            } else {
                if (response.status === Constants.HTTP_USER_NOT_FOUND) {
                    this.setState({
                        isRequestDone: false,
                        isSubmited: false,
                        error: {
                            status: true,
                            message: "loginForm.identifier.userNotFound"
                        },
                        notification: {
                            status: false
                        }
                    })
                } else {
                    this.setState({
                        isRequestDone: false,
                        isSubmited: false,
                        error: {
                            status: true,
                            message: "error"
                        },
                        notification: {
                            status: false
                        }
                    })
                }
            }
        }
    }

    handleIdentifierChange(e) {
        this.setState({
            identifier: e.target.value,
            error: {status: false},
            notification: {status: false}
        })
    }

    handlePasswordChange(e) {
        this.setState({
            password: e.target.value,
            error: {status: false},
            notification: {status: false}
        })
    }

    render() {
        if (Session.isLoggedIn()) {
            return <Navigate to={Routing.game.path}/>
        }

        return <div className="login">
            {(this.state.isSubmited && !this.state.isRequestDone)
                ? <LoadingOverlay/>
                : <form onSubmit={this.handleSubmit} className="login-form">

                    <NavLink reloadDocument to={Routing.app_home.path} className="nav-item login-back"
                             title={i18n.t("loginForm.back")}>
                        <FontAwesomeIcon icon="fas fa-arrow-left"/> {i18n.t("loginForm.back")}
                    </NavLink>

                    <h1 className="login-title">{i18n.t("loginForm.singIn")}</h1>
                    {this.state.error.status &&
                        <p className="login-error">{i18n.t(this.state.error.message)}</p>
                    }
                    {this.state.notification.status &&
                        <p className="login-notification">{i18n.t(this.state.notification.message)}</p>
                    }
                    <InputField type="text" name="identifier" value={this.state.identifier} required={true}
                                onChange={this.handleIdentifierChange}>
                        {i18n.t("loginForm.identifier.text")}
                    </InputField>
                    <InputField type="password" name="password" value={this.state.password} required={true}
                                onChange={this.handlePasswordChange}>
                        {i18n.t("loginForm.password.text")}
                    </InputField>

                    <ButtonPrimary type="submit" name="loginButton" className="login-button" nosound>
                        {i18n.t("session.login")}
                    </ButtonPrimary>

                    <a href={Routing.app_login.path} className="login-forgotten-password"
                       onClick={this.handleForgottenPassword}> {i18n.t("loginForm.password.forgotten")} </a>
                </form>
            }
        </div>
    }
}