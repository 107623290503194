import React from "react"
import i18n from "i18next"
import GlitchText from "../general/GlitchText"

export class UserAvatar extends React.Component {
    static defaultProps = {
        user: null
    }

    constructor(props) {
        super(props)

        this.state = {
            avatarType: 1
            // avatarType: 2
        }
    }

    render() {
        //TODO: temporaire à modifier lors de l'implémentation des vrais avatars
        const imgLink = "/img/user-avatar.png"
        // const imgLink = (this.props.user.sexe === "féminin") ? "/img/avatar-femme2.png" : "/img/user-avatar.png"
        return (
            <div className="user-avatar-full">
                <div className="avatar-wrapper">
                    <img src={imgLink} alt="user avatar"/>
                </div>
                <div className="message-wrapper">
                    <GlitchText className="avatar-sub-message">
                        {i18n.t("game.catchPhrase")} {this.props.user.pseudo}
                    </GlitchText>
                </div>
            </div>
        )
    }
}