import React from "react"
import "./glitchText.css"

export default class GlitchText extends React.Component {
    render() {
        const {children, title,className, stacks = 3} = this.props
        let stackSpans = []

        for (let i = 0; i < stacks; i++) {
            stackSpans.push(<span style={{"--index": i}} key={`glitch${i}`}>{children}</span>)
        }

        return <div className={`glitchText-container ${className}`} title={title}>
            <div className="glitchText-stack" style={{"--stacks": stacks}}>
                {stackSpans}
            </div>
        </div>
    }
}