import "./profileMenu.css"
import {NavLink} from "react-router-dom"
import {routes as Routing} from "../../services/RoutesHelper"
import {useTranslation} from "react-i18next";

export default function ProfileMenu({active}) {
    const general = (active === "general") ? "active":""
    const avatar = (active === "avatar") ? "active":""
    const history = (active === "history") ? "active":""
    const statistic = (active === "statistic") ? "active":""
    const {t} = useTranslation()
    return <div className="profile-head">
        <div className="flex-start" style={{paddingLeft: 20, paddingTop: 20}}>
            <NavLink to={Routing.game.path} style={{textDecoration: "none"}}>
                <div className="profile-btn-back">
                    <div className="txt-back">{t("profile.back")}</div>
                    <div className="ligne-top"></div>
                </div>
            </NavLink>
            <div className="flex profile-menu">
                <div className={`w-25 ${general}`}>{t("profile.menu.general")}</div>
                <div className={`w-25 ${avatar}`}>{t("profile.menu.avatar")}</div>
                <div className={`w-25 ${history}`}>{t("profile.menu.history")}</div>
                <div className={`w-25 ${statistic}`}>{t("profile.menu.statistic")}</div>
            </div>
        </div>
    </div>
}
